import React from 'react'

import { Button } from '@arch-log/webapp.atomics/components/Button'
import { Context as StyleContext } from './Style/Context'

/**
 * Styled Component for Form Submit
 */
export const Reset = ({
  // Props
  className,
  type = 'reset',
  ...props
}) => {
  const { styles } = React.useContext(StyleContext)
  return (
    <Button
      className={className ?? styles.Form_Buttons_Reset}
      type={type}
      {...props}
    />
  )
}
