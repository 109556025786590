import React from 'react'

import { Field } from 'redux-form'

import * as FieldRenderers from './Field/Renderers'
import * as FieldNames from './FieldNames'
import * as Inputs from './Field/Inputs'

/**
 */
export const Email = ({
  render: Renderer = FieldRenderers.Email,
  ...props
}) => {
  return (
    <Field
      name={FieldNames.Email}
      component={Renderer}
      components={{ Input: Inputs.Email }}
      placeholder="Email"
      {...props}
    />
  )
}
