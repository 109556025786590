import React from 'react'

import { Field, Label, Input } from './Styled'

import * as ResetPasswordRequestForm from '@arch-log/webapp.modules/auth/components/ResetPasswordRequestForm'
import * as Literals from './Literals'
import * as styles from './styles.module.scss'

/**
 */
export const Email = () => {
  const { formatPlaceholder, formatFieldError } = Literals.useLiterals()

  return (
    <Field>
      <Label>
        <Literals.Email />
      </Label>
      <Input>
        <ResetPasswordRequestForm.Fields.Email
          className={styles.FieldInputItem}
          formatPlaceholder={formatPlaceholder}
          formatError={formatFieldError}
        />
      </Input>
    </Field>
  )
}
