import React from 'react'

import * as RedirectUri from 'components/RedirectUri'
import { navigate } from '@arch-log/webapp.lib/RoutingHelper'
import { Logout } from '@arch-log/webapp.modules/auth/components/Logout'

import * as OnMemoryStorage from '@arch-log/webapp.modules/auth.v2/components/OnMemoryStorage'

/**
 */
export const Page = () => {
  return (
    <RedirectUri.Initializer defaultRedirectUri="/">
      <LogoutAndRedirect />
    </RedirectUri.Initializer>
  )
}
const LogoutAndRedirect = () => {
  const { redirectUri } = React.useContext(RedirectUri.Context)

  return (
    <Logout
      onComplete={() => {
        console.log('logged out')

        OnMemoryStorage.initAuthorization(null)

        window.location.href = redirectUri
      }}
    ></Logout>
  )
}

export default Page
