export const selectModule = (state) => state.module.auth

/**
 */
export const selectIsInitialized = (state) => selectModule(state).isInitialized

/**
 */
export const selectToken = (state) => selectModule(state).token

/**
 */
export const selectIsAuthenticated = (state) =>
  selectModule(state).isAuthenticated


/**
 */
export const selectUserId = (state) =>
  selectModule(state).uid

/**
 */
export const selectErrors = (state) => selectModule(state).errors
