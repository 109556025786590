"use strict";

exports.__esModule = true;
var _exportNames = {
  ChangeLanguage: true,
  PageLocale: true
};
exports.PageLocale = exports.ChangeLanguage = void 0;
var ChangeLanguage = _interopRequireWildcard(require("./ChangeLanguage"));
exports.ChangeLanguage = ChangeLanguage;
var PageLocale = _interopRequireWildcard(require("./PageLocale"));
exports.PageLocale = PageLocale;
var _RedirectPage = require("./RedirectPage");
Object.keys(_RedirectPage).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _RedirectPage[key]) return;
  exports[key] = _RedirectPage[key];
});
var _Link = require("./Link");
Object.keys(_Link).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Link[key]) return;
  exports[key] = _Link[key];
});
var _navigate = require("./navigate");
Object.keys(_navigate).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _navigate[key]) return;
  exports[key] = _navigate[key];
});
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }