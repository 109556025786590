import React from 'react'

import { Context } from './Context'

/**
 *
 */
export const Authenticated = ({
  children,
  onAuthenticated = null,
  onAnonymous = null,
}) => {
  const { isAuthenticated } = React.useContext(Context)

  React.useEffect(() => {
    if (isAuthenticated && onAuthenticated) {
      onAuthenticated()
    } else if (!isAuthenticated && onAnonymous) {
      onAnonymous()
    }
  }, [isAuthenticated])

  if (isAuthenticated) {
    return children
  }

  return null
}
