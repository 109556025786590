export * from '@arch-log/webapp.modules/utils/components/FormTemplate/Field/Invalidations'

/**
 */
export const InvalidPasswordLength = 'Field.InvalidPasswordLength'

/**
 */
export const MissingPasswordLowerCase = 'Field.MissingPasswordLowerCase'

/**
 */
export const MissingPasswordUpperCase = 'Field.MissingPasswordUpperCase'

/**
 */
export const MissingPasswordNumeral = 'Field.MissingPasswordNumeral'

/**
 */
export const MissingPasswordSymbol = 'Field.MissingPasswordSymbol'
