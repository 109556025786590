/**
 */
export const modelToForm = (model) => ({
  ...model,
})

/**
 */
export const formToModel = (form) => ({
  ...form,
})
