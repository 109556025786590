import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Message = translated('pages')(
  'ResetPasswordRequest.Flow.Steps.CompleteStep.Message',
)

/**
 */
export const CompleteButton = translated('pages')(
  'ResetPasswordRequest.Flow.Steps.CompleteStep.CompleteButton',
)
