import React from 'react'

import { Context } from './Context'

/**
 */
export const Initializer = ({ children }) => {
  const [setting, setSetting] = React.useState({})

  React.useLayoutEffect(() => {
    const params = new URLSearchParams(window.location.search)

    switch (params.get('mode')) {
      case 'plugin':
        setSetting({
          enableResetPassword: false,
          showFooterLinks: false,
          enableLogoLink: false,
          enableSignUp: false,
        })
        break
      default:
        // default
        setSetting({
          // use Default setting
        })
        break
    }
  }, [])

  return <Context.Provider value={setting}>{children}</Context.Provider>
}
