import React from 'react'

import * as Icons from '@arch-log/webapp.shared/Icons'
import * as styles from './styles.module.scss'

/**
 */
export const LoginIcon = () => <Icons.Login className={styles.LoginIcon} />

/**
 */
export const ProjectIcon = () => (
  <Icons.Project className={styles.ProjectIcon} />
)
