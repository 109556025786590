import React from 'react'

import { Field } from 'redux-form'

export const Username = ({
  name = 'username',
  component = 'input',
  ...props
}) => <Field name={name} type="text" component={component} {...props} />

export const Password = ({
  name = 'password',
  component = 'input',
  ...props
}) => <Field name={name} type="password" component={component} {...props} />

export const RememberMe = ({
  name = 'rememberme',
  label = 'Remember Me',
  ...props
}) => (
  <Field
    name={name}
    component={(field) => (
      <label>
        <input type="checkbox" {...props} /> {label}
      </label>
    )}
  />
)
