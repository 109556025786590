import React from 'react'

import * as styles from './styles.module.scss'
/**
 *
 */
export const BaseForm = ({
  form,
  handleSubmit,
  className = styles.DefaultForm,
  children,
}) => {
  return (
    <form id={form} onSubmit={handleSubmit} className={className}>
      {children}
    </form>
  )
}
