import { all, takeLeading, put, call, race, take } from 'redux-saga/effects'
import { getErrorMessage } from '@arch-log/webapp.modules/utils/components/FormTemplate/utils/FormHelper'
import { log } from './utils'

import * as actions from './actions'
import * as api from './api'

import * as form from 'redux-form'

/**
 * Initialize
 */
function* handleInit(action) {
  try {
    log(action)

    let isAuthenticated

    try {
      const user = yield call(api.load)
      isAuthenticated = true

      yield put(actions.authenticated(user.id))
    } catch (err) {
      isAuthenticated = false
    }

    yield put(actions.initSuccess({ isAuthenticated }))
  } catch (e) {
    console.error(e)
    actions.initFailure(e)
  }
}

/**
 * login
 */
function* handleLogin(action) {
  log(action)

  try {
    yield put(form.startSubmit('auth_login'))

    const { username: email, password, rememberme = false } = action.payload

    //
    const res = yield call(api.login, email, password, { rememberme })

    yield put(actions.loginSuccess(res))

    yield put(actions.authenticated(res.account.id))
  } catch (e) {
    console.error(e)
    yield put(actions.loginFailure(e))
  }
}

function* handleLogout(action) {
  try {
    log(action)

    yield call(api.logout)

    if (action.payload.onComplete) {
      action.payload.onComplete()
    }
    yield put(actions.logoutSuccess())
  } catch (e) {
    console.error(e)
    yield put(actions.logoutFailure(e))
  }
}

/**
 */
function* handleLoginFormSubmit(action) {
  try {
    log(action)

    yield put(form.startSubmit('auth_login'))

    yield put(actions.login(action.payload.data))

    const [success, failure] = yield race([
      take('MODULE/AUTH/LOGIN/SUCCESS'),
      take('MODULE/AUTH/LOGIN/FAILURE'),
    ])

    if (failure) {
      throw failure
    }

    yield put(form.stopSubmit('auth_login'))
    yield put(form.setSubmitSucceeded('auth_login'))

    if (action.payload.props.onSubmitSuccess) {
      const account = success.payload.account
      action.payload.props.onSubmitSuccess(
        { ...action.payload.data, account },
        action.payload.dispatch,
        action.payload.props,
      )
    }
  } catch (e) {
    console.error(e)

    try {
      yield put(
        form.stopSubmit('auth_login', {
          _error: getErrorMessage(e.payload.error),
        }),
      )

      yield put(
        form.setSubmitFailed('auth_login', [action.payload.props.values]),
      )

      if (action.payload.props.onSubmitFail) {
        action.payload.props.onSubmitFail(
          { _error: 'submit error' },
          action.payload.dispatch,
          e,
          action.payload.props,
        )
      }
    } catch (e) {
      console.error(e)
    }
  }
}

function* handleChangePassword(action) {
  try {
    log(action)

    yield call(api.changePassword, null, action.payload.data.new_password)

    yield put(actions.changePasswordSuccess())
  } catch (e) {
    console.error(e)
    yield put(actions.changePasswordFailure(e))
  }
}

/**
 */
function* handleChangePasswordFormSubmit(action) {
  const formName = action.payload.props.form

  try {
    log(action)

    yield put(form.startSubmit(formName))

    yield put(actions.changePassword(action.payload.data))

    const [success, failure] = yield race([
      take('MODULE/AUTH/CHANGE_PASSWORD/SUCCESS'),
      take('MODULE/AUTH/CHANGE_PASSWORD/FAILURE'),
    ])

    if (failure) {
      throw failure
    }

    yield put(form.stopSubmit(formName))
    yield put(form.setSubmitSucceeded(formName))

    if (action.payload.props.onSubmitSuccess) {
      action.payload.props.onSubmitSuccess(
        null,
        action.payload.dispatch,
        action.payload.props,
      )
    }
  } catch (e) {
    console.error(e)

    try {
      yield put(
        form.stopSubmit(formName, {
          _error: getErrorMessage('Form.SubmitFailed'),
        }),
      )

      yield put(form.setSubmitFailed(formName, [action.payload.props.values]))

      if (action.payload.props.onSubmitFail) {
        action.payload.props.onSubmitFail(
          { _error: 'submit error' },
          action.payload.dispatch,
          e,
          action.payload.props,
        )
      }
    } catch (e) {
      console.error(e)
    }
  }
}

/**
 * FIXME
 */
function* handleResetPassword(action) {
  try {
    log(action)

    const { email } = action.payload

    yield call(api.resetPassword, email)

    yield put(actions.resetPasswordSuccess())
  } catch (e) {
    console.error(e)
    yield put(actions.resetPasswordFailure(e))
  }
}

/**
 */
function* handleResetPasswordRequestFormSubmit(action) {
  const formName = action.payload.props.form

  try {
    log(action)

    yield put(form.startSubmit(formName))

    yield put(actions.resetPassword(action.payload.data))

    const [success, failure] = yield race([
      take('MODULE/AUTH/RESET_PASSWORD/SUCCESS'),
      take('MODULE/AUTH/RESET_PASSWORD/FAILURE'),
    ])

    if (failure) {
      throw failure
    }

    yield put(form.stopSubmit(formName))
    yield put(form.setSubmitSucceeded(formName))

    if (action.payload.props.onSubmitSuccess) {
      action.payload.props.onSubmitSuccess(
        null,
        action.payload.dispatch,
        action.payload.props,
      )
    }
  } catch (e) {
    console.error(e)

    try {
      yield put(
        form.stopSubmit(formName, {
          _error: 'SubmitFailed',
        }),
      )

      yield put(form.setSubmitFailed(formName, ...action.payload.props.fields))

      if (action.payload.props.onSubmitFail) {
        action.payload.props.onSubmitFail(
          { _error: 'submit error' },
          action.payload.dispatch,
          e,
          action.payload.props,
        )
      }
    } catch (e) {
      console.error(e)
    }
  }
}

/**
 */
function* handleResetPasswordConfirm(action) {
  try {
    log(action)

    const { password, token } = action.payload

    yield call(api.resetPasswordConfirm, password, token)

    yield put(actions.resetPasswordConfirmSuccess())
  } catch (e) {
    console.error(e)
    yield put(actions.resetPasswordConfirmFailure(e))
  }
}

/**
 */
function* handleResetPasswordConfirmFormSubmit(action) {
  const formName = action.payload.props.form

  try {
    log(action)

    yield put(form.startSubmit(formName))

    yield put(actions.resetPasswordConfirm(action.payload.data))

    const [success, failure] = yield race([
      take('MODULE/AUTH/RESET_PASSWORD_CONFIRM/SUCCESS'),
      take('MODULE/AUTH/RESET_PASSWORD_CONFIRM/FAILURE'),
    ])

    if (failure) {
      throw failure.payload.error
    }

    yield put(form.stopSubmit(formName))
    yield put(form.setSubmitSucceeded(formName))

    if (action.payload.props.onSubmitSuccess) {
      action.payload.props.onSubmitSuccess(
        null,
        action.payload.dispatch,
        action.payload.props,
      )
    }
  } catch (err) {
    try {
      yield put(
        form.stopSubmit(formName, {
          _error: `Form.${err}`,
        }),
      )

      //yield put(form.setSubmitFailed(formName, ...action.payload.props.fields))

      if (action.payload.props.onSubmitFail) {
        action.payload.props.onSubmitFail(
          { _error: 'submit error' },
          action.payload.dispatch,
          err,
          action.payload.props,
        )
      }
    } catch (err) {
      console.error(err)
    }
  }
}

export function* sagas() {
  yield all([
    takeLeading('MODULE/AUTH/INIT/REQUEST', handleInit),
    takeLeading('MODULE/AUTH/LOGIN/REQUEST', handleLogin),
    takeLeading('MODULE/AUTH/LOGOUT/REQUEST', handleLogout),
    takeLeading('MODULE/AUTH/CHANGE_PASSWORD/REQUEST', handleChangePassword),
    takeLeading('MODULE/AUTH/LOGIN_FORM/SUBMIT', handleLoginFormSubmit),
    takeLeading(
      'MODULE/AUTH/CHANGE_PASSWORD_FORM/SUBMIT',
      handleChangePasswordFormSubmit,
    ),
    takeLeading('MODULE/AUTH/RESET_PASSWORD/REQUEST', handleResetPassword),
    takeLeading(
      'MODULE/AUTH/RESET_PASSWORD_REQUEST_FORM/SUBMIT',
      handleResetPasswordRequestFormSubmit,
    ),
    takeLeading(
      'MODULE/AUTH/RESET_PASSWORD_CONFIRM/REQUEST',
      handleResetPasswordConfirm,
    ),
    takeLeading(
      'MODULE/AUTH/RESET_PASSWORD_CONFIRM_FORM/SUBMIT',
      handleResetPasswordConfirmFormSubmit,
    ),
  ])
}
