import React from 'react'

//
import { Authenticated } from '@arch-log/webapp.modules/auth/components/Authenticated'
import { ForAnonymous } from '@arch-log/webapp.modules/auth/components/ForAnonymous'
import { translated } from '@arch-log/webapp.lib/TranslationHelper'

import * as PageHeadTemplates from '@arch-log/webapp.shared/Page/Head'
import * as RedirectUri from 'components/RedirectUri'
import * as Layout from 'layouts/LoginPageLayout'
import * as AuthenticatedPage from 'partials/AuthenticatedPage'
import * as Partial from 'partials/LoginPage'

/**
 */
const AuthenticatedContent =
  // Component
  () => {
    const { redirectUri } = React.useContext(RedirectUri.Context)

    if (redirectUri) {
      return <RedirectUri.Redirect />
    }

    return <AuthenticatedPage.Content />
  }

/**
 */
export const Head = ({ pageContext }) => {
  return <PageHeadTemplates.Language language={pageContext?.language} />
}

/**
 *
 */
export const Page =
  // BaseComponent
  () => {
    const [visiblitySetting, setVisibilitySetting] = React.useState({
      showResetPassword: true,
      showRegistration: true,
      showLinks: true,
    })

    React.useLayoutEffect(() => {
      const params = new URLSearchParams(window.location.search)

      switch (params.get('mode')) {
        case 'plugin':
          setVisibilitySetting({
            showResetPassword: false,
            showRegistration: false,
            showLinks: false,
          })
          break
        default:
      }
    }, [])

    return (
      <RedirectUri.Initializer>
        <Authenticated>
          <AuthenticatedContent />
        </Authenticated>
        <ForAnonymous>
          <Layout.Container>
            <Layout.Title>
              <LoginTitle />
            </Layout.Title>
            <Layout.Body>
              <Partial.Form.Default />
              <Partial.Footer.Default {...visiblitySetting} />
            </Layout.Body>
            <Layout.Links>
              <Partial.Links.Default showLinks={visiblitySetting.showLinks} />
            </Layout.Links>
          </Layout.Container>
        </ForAnonymous>
      </RedirectUri.Initializer>
    )
  }

/**
 *
 */
export default Page

/**
 */
export const LoginTitle = translated('page.login')('Login')
