import React from 'react'

import { PageNotFound } from '@arch-log/webapp.shared/Errors'

import * as styles from './styles.module.scss'

const Page = () => (
  <div className={styles.ErrorPage_Container}>
    <PageNotFound />
  </div>
)

export default Page
