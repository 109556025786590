"use strict";

exports.__esModule = true;
exports.resolveContentPath = exports.log = void 0;
const log = exports.log = require("debug")("plugin:gatsby-plugin-localization");

/**
 */
const resolveContentPath = p => {
  return p.replace(/^\/[a-z]{2}\//, '/');
};
exports.resolveContentPath = resolveContentPath;