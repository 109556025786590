import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 */
export const Header = ({ children }) => (
  <div className={styles.Header}>{children}</div>
)

/**
 */
export const Body = ({ children }) => (
  <div className={styles.Body}>{children}</div>
)

/**
 */
export const Footer = ({ children }) => (
  <div className={styles.Footer}>{children}</div>
)

/**
 */
export const Error = ({ children }) => (
  <span className={styles.Error}>{children}</span>
)

///**
// */
//export const FieldLabel = ({ children }) => (
//  <span className={ styles.FieldLabel }>{ children }</span>
//)
/**
 */
//export const CheckBox = ({ children }) => (
//  <div className={ styles.CheckBox }>{ children }</div>
//)
