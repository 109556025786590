import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'

import * as Layout from './Layout'
import * as Components from './Components'

/**
 */
export const Default = withDefaultRenderComponent(
  ({
    components: {
      Message: messageComponents,
      Complete: completeComponents,
      Layout: {
        Container = Layout.Container,
        Body = Layout.Body,
        Footer = Layout.Footer,
      } = {},
    } = {},
  }) => {
    return (
      <Container>
        <Body>
          <Components.Message components={messageComponents} />
        </Body>
        <Footer>
          <Components.Complete components={completeComponents} />
        </Footer>
      </Container>
    )
  },
)
