import React from 'react'

import * as Forms from '@arch-log/webapp.modules/auth/components/Forms'
import * as Literals from './Literals'
import * as Layout from './Layout'
import * as styles from './styles.module.scss'

/**
 */
const ErrorMessage = Forms.Errors

/**
 */
export const Default =
  // BaseComponent
  () => {
    const { formatFormError, formatFieldPlaceholder } = Literals.useLiterals()

    return (
      <Layout.Container>
        <Forms.LoginForm styles={styles}>
          <Layout.Header>
            <div className={styles.ErrorMessage}>
              <ErrorMessage formatError={formatFormError} />
            </div>
          </Layout.Header>

          <Layout.Body>
            <Forms.Fields.Username
              placeholder={formatFieldPlaceholder('Email')}
              className={styles.InputField}
            />
            <Forms.Fields.Password
              placeholder={formatFieldPlaceholder('Password')}
              className={styles.InputField}
            />
          </Layout.Body>

          <Layout.Footer>
            <Forms.Buttons.Submit className={styles.SubmitButton}>
              <Literals.Submit />
            </Forms.Buttons.Submit>
            {/*
        <Layout.CheckBox>
          <input id="omission" type="checkbox" />
          <label htmlFor="omission">次回から入力を省略する</label>
        </Layout.CheckBox>
*/}
          </Layout.Footer>
        </Forms.LoginForm>
      </Layout.Container>
    )
  }
