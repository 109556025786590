import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const LinksContainer = ({ children, ...props }) => (
    <div className={styles.LinksContainer} {...props}>
      {children}
    </div>
)