import {
  Invalidations,
  Validators as Requirements,
} from '@arch-log/webapp.lib/form/validation'

import * as FieldInvalidations from './Invalidations'
import * as FieldNames from '../FieldNames'

/**
 */
export const validate = (values) => {
  return [validateEmail(values)]
}

/**
 */
const validateEmail = (values) => {
  try {
    Requirements.Required(values[FieldNames.Email])
    Requirements.Pattern.Email(values[FieldNames.Email])
  } catch (err) {
    switch (err) {
      case Invalidations.Missing:
        return [FieldNames.Email, FieldInvalidations.Missing]
      case Invalidations.PatternMismatch:
        return [FieldNames.Email, FieldInvalidations.EmailPatternMismatch]
      default:
        return null
    }
  }
}
