import { all, call, put, race, take, takeLatest } from 'redux-saga/effects'

import { navigate } from '@arch-log/webapp.lib/RoutingHelper'

import * as actions from './actions'

/**
 */
import { log } from './util'

/**
 * Initialize Store
 */
function* handleInit(action) {
  try {
    log(action)

    // Do Initialization here
    log('init success')

    yield put(actions.initSuccess())
  } catch (e) {
    console.error(e)
    yield put(actions.initFailure(e))
  }
}

/**
 */
export function* sagas() {
  yield all([takeLatest('STORE/INIT/REQUEST', handleInit)])
}
