import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Reference from './Reference'

import * as selector from '../selectors'
import * as actions from '../actions'

/**
 */
const DefaultLoading = () => <span>Loading</span>

/**
 * Initialize My accoun
 */
export const MyInitializer = connect(
  // mapStateToProps,
  (state) => ({
    isLoaded: selector.selectMe(state).isLoaded,
    entry: selector.selectMeEntry(state),
  }),
  // mapDispatchToProps,
  (dispatch) =>
    bindActionCreators(
      {
        load: actions.loadMe,
      },
      dispatch,
    ),
)(
  //
  ({
    children,
    isLoaded,
    forceReload = false,
    entry,
    load,
    loading: Loading = DefaultLoading,
    asynchronous: isAsynchronous = false,
  }) => {
    React.useEffect(() => {
      if (forceReload || !isLoaded) {
        load()
      }
    }, [])

    if (!isAsynchronous && !isLoaded) {
      return <Loading />
    }

    if (isAsynchronous && !entry) {
      return <>{children}</>
    }

    return <Reference.Provider account={entry}>{children}</Reference.Provider>
  },
)
