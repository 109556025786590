import React from 'react'

import { useLocaleRedirection } from '@arch-log/webapp.lib/react.hook'

/**
 */
const Page = () => {
  useLocaleRedirection('/resetPassword/')

  return null
}

/**
 */
export default Page
