import React from 'react'

import * as Icons from '@arch-log/webapp.shared/Icons'

import * as styles from './styles.module.scss'

/**
 */
export const LanguageIcon = () => (
  <Icons.Language className={styles.MenuItem_Icon} />
)
