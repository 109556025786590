import { defaultClient as httpClient } from '../utils/http'

import * as FormInvalidation from '@arch-log/webapp.modules/auth/components/Forms/Invalidations'

/**
 */
export const load = () => {
  return httpClient
    .post('/user/details')
    .then(({ data }) => data)
    .then(({ code, data = null, message = null }) => {
      if (code !== 0) {
        throw { code, message }
      }
      return data
    })
}

/**
 */
export const changePassword = async (oldPassword, newPassword) => {
  const formData = new URLSearchParams()

  formData.append('new_password', newPassword)

  return httpClient
    .post('/user/set_password', formData)
    .then(({ data }) => data)
    .then(({ code, data = null, message = null }) => {
      if (code !== 0) {
        throw { code, message }
      }
      return data
    })
}

/**
 */
export const login = (username, password, { remember = false }) => {
  return httpClient
    .post('/user/login', {
      email: username,
      password: password,
      remember: remember,
    })
    .then(({ data }) => data)
    .then(({ code, data = null, message = null }) => {
      if (code !== 0) {
        switch (code) {
          case 1:
            throw FormInvalidation.UserAndPasswordDoNotMatch
          default:
            throw message
        }
      }

      return data
    })
    .then(
      ({
        permissions: projectPermissions,
        active_project_id: activeProject = null,
        user: account,
      }) => ({
        projectPermissions,
        activeProject,
        account,
      }),
    )
}

/**
 */
export const logout = async () => {
  return await httpClient
    .post('/user/logout', {})
    .then(({ data }) => data)
    .then(({ code, data = null, message = null }) => {
      if (code !== 0) {
        throw { code, message }
      }

      return data
    })
}

/**
 */
export const resetPassword = (email) => {
  const formData = new URLSearchParams()

  formData.append('email', email)

  return httpClient
    .post('/user/reset_password', formData)
    .then(({ data }) => data)
    .then(({ code, data = null, message = null }) => {
      if (code !== 0) {
        throw { code, data, message }
      }

      return data
    })
}

/**
 */
export const resetPasswordConfirm = (password, token) => {
  return httpClient
    .post('/user/reset_password_confirm', { password: password, token: token })
    .then(({ data }) => data)
    .then(({ code, data = null, message = null }) => {
        switch(code) {
          case 0:
            break;
          case 2:
            throw 'Errors.InvalidToken'
          default:
            throw message 
        }

      return data
    })
}
