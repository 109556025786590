import React from 'react'

import { ForAnonymous } from '@arch-log/webapp.modules/auth/components/ForAnonymous'
import { navigate } from '@arch-log/webapp.lib/RoutingHelper'

import * as PageHeadTemplates from '@arch-log/webapp.shared/Page/Head'
import * as Partials from 'partials/ResetPasswordRequestPage'


/**
 */
export const Head = ({ pageContext }) => {
 return <PageHeadTemplates.Language language={pageContext?.language} />
}

/**
 */
const Page = () => {
  return (
    <ForAnonymous
      onAuthenticated={() => {
        navigate('/')
      }}
    >
      <Partials.Flow />
    </ForAnonymous>
  )
}

/**
 */
export default Page
