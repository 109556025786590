import { createActions } from 'redux-actions'
import { ACTION_PREFIX } from './consts'

export const {
  init: { request: init, success: initSuccess, failure: initFailure },
  me: {
    load: { request: loadMe, success: loadMeSuccess, failure: loadMeFailure },
    unload: unloadMe,
    update: {
      request: updateMe,
      success: updateMeSuccess,
      failure: updateMeFailure,
    },
    updateEmail: {
      request: updateEmail,
      success: updateEmailSuccess,
      failure: updateEmailFailure,
    },
    updateForm: { submit: submitUpdateForm },
    updateChangeEmailForm: { submit: submitUpdateChangeEmailForm },
  },
} = createActions(
  {
    INIT: {
      REQUEST: () => ({}),
      SUCCESS: () => {},
      FAILURE: (error) => ({ error }),
    },
    ME: {
      LOAD: {
        REQUEST: () => ({}),
        SUCCESS: (data) => ({ data }),
        FAILURE: (error) => ({ error }),
      },
      UPDATE: {
        REQUEST: (data) => ({ data }),
        SUCCESS: (data) => ({ data }),
        FAILURE: (error) => ({ error }),
      },
      UPDATE_FORM: {
        SUBMIT: (data, dispatch, props) => ({ data, dispatch, props }),
      },
      UPDATE_EMAIL: {
        REQUEST: (data) => ({ data }),
        SUCCESS: (data) => ({ data }),
        FAILURE: (error) => ({ error }),
      },
      UPDATE_CHANGE_EMAIL_FORM: {
        SUBMIT: (data, dispatch, props) => ({ data, dispatch, props }),
      },
      UNLOAD: () => ({}),
    },
  },
  {
    prefix: ACTION_PREFIX,
  },
)
