import React from 'react'

import * as Links from './Links'
import * as styles from './styles.module.scss'

/**
 */
const DefaultContainer = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 */
const DefaultRow = ({ children }) => (
  <div className={styles.Row}>{children}</div>
)

/**
 */
export const Default = ({
  components: { Container = DefaultContainer, Row = DefaultRow } = {},
}) => (
  <Container>
    <Row>
      <Links.ToNews />
      <Links.ToPrSite />
      <Links.ToAbout />
      <Links.ToShowcase />
      <Links.ToHowTo />
      <Links.ToFaq />
      <Links.ToSystemRequirements />
      <Links.ToContact />
    </Row>
    <Row>
      <Links.ToPublish />
      <Links.ToPrivateAccount />
      <Links.ToReservation />
      <Links.ToProductRequestForm />
    </Row>
    <Row>
      <Links.ToCompanyProfile />
      <Links.ToTerms />
      <Links.ToCommercialTransactions />
      <Links.ToPrivacyPolicy />
    </Row>
  </Container>
)
