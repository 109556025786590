import React from 'react'

import * as styles from './styles.module.scss'
import * as Literals from './Literals'

/**
 */
export const LinkContainer = ({ children, ...props }) => (
  <div className={styles.LinkContainer} {...props}>
    {children}
  </div>
)

/**
 */
export const RegistrationContainer = ({ children, ...props }) => (
  <div className={styles.RegistrationContainer} {...props}>
    {children}
  </div>
)

/**
 */
export const LiteralsRegistration = ({ ...props }) => (
  <div className={styles.LiteralsRegistration}  {...props}>
    <Literals.Registration/>
  </div>
)
