import {
  fieldValueOf,
  toPhoneNumber,
  toPhoneNumberFirst,
  toPhoneNumberSecond,
  toPhoneNumberThird,
} from '@arch-log/webapp.modules/utils/entity/Helper'

import * as EntityFields from './fields'
import * as ModelFields from '../model/fields'

import * as Address from '@arch-log/webapp.modules/address/entity'
import * as ObjectHelper from '@arch-log/webapp.lib/ObjectHelper'

const addressEntityFieldMapping = {
  [Address.Fields.PostalCode]: EntityFields.PostalCode,
  [Address.Fields.StateOrPrefecture]: EntityFields.StateOrPrefecture,
  [Address.Fields.City]: EntityFields.City,
  [Address.Fields.Street]: EntityFields.Street,
  [Address.Fields.Building]: EntityFields.Building,
}

/**
 */
export const modelToEntity = (model) => {
  return {
    [EntityFields.Company]: fieldValueOf(model, ModelFields.Company),
    [EntityFields.Division]: fieldValueOf(model, ModelFields.Division),
    [EntityFields.Email]: fieldValueOf(model, ModelFields.Email),
    [EntityFields.FirstName]: fieldValueOf(model, ModelFields.FirstName),
    [EntityFields.IsIndividual]: fieldValueOf(
      model,
      ModelFields.IsIndividual,
      false,
    )
      ? 1
      : 0,
    [EntityFields.JobTitle]: fieldValueOf(model, ModelFields.JobTitle),
    [EntityFields.LastName]: fieldValueOf(model, ModelFields.LastName),
    [EntityFields.PhoenticFirstName]: fieldValueOf(
      model,
      ModelFields.PhoenticFirstName,
    ),
    [EntityFields.PhoenticLastName]: fieldValueOf(
      model,
      ModelFields.PhoenticLastName,
    ),
    [EntityFields.PhoneNumber]: toPhoneNumber(
      fieldValueOf(model, ModelFields.PhoneNumberFirst),
      fieldValueOf(model, ModelFields.PhoneNumberSecond),
      fieldValueOf(model, ModelFields.PhoneNumberThird),
    ),
    [EntityFields.TypeOfBusiness]: fieldValueOf(
      model,
      ModelFields.TypeOfBusiness,
    ),
    [EntityFields.Address]: Address.Converter.modelToEntity(
      fieldValueOf(model, ModelFields.Address),
      {
        entityFieldMapping: addressEntityFieldMapping,
      },
    ),
  }
}

/**
 */
export const entityToModel = (entity) => ({
  [ModelFields.Company]: fieldValueOf(entity, EntityFields.Company),
  [ModelFields.Division]: fieldValueOf(entity, EntityFields.Division),
  [ModelFields.Email]: fieldValueOf(entity, EntityFields.Email),
  [ModelFields.FirstName]: fieldValueOf(entity, EntityFields.FirstName),
  [ModelFields.Id]: fieldValueOf(entity, EntityFields.Id),
  [ModelFields.IsIndividual]: fieldValueOf(
    entity,
    EntityFields.IsIndividual,
    false,
  ),
  [ModelFields.JobTitle]: fieldValueOf(entity, EntityFields.JobTitle),
  [ModelFields.LastName]: fieldValueOf(entity, EntityFields.LastName),
  [ModelFields.PhoenticFirstName]: fieldValueOf(
    entity,
    EntityFields.PhoenticFirstName,
  ),
  [ModelFields.PhoenticLastName]: fieldValueOf(
    entity,
    EntityFields.PhoenticLastName,
  ),
  [ModelFields.PhoneNumberFirst]: toPhoneNumberFirst(
    fieldValueOf(entity, EntityFields.PhoneNumber),
  ),
  [ModelFields.PhoneNumberSecond]: toPhoneNumberSecond(
    fieldValueOf(entity, EntityFields.PhoneNumber),
  ),
  [ModelFields.PhoneNumberThird]: toPhoneNumberThird(
    fieldValueOf(entity, EntityFields.PhoneNumber),
  ),
  [ModelFields.TypeOfBusiness]: fieldValueOf(
    entity,
    EntityFields.TypeOfBusiness,
  ),
  [ModelFields.Address]: Address.Converter.entityToModel(
    fieldValueOf(entity, EntityFields.Address),
    {
      entityFieldMapping: addressEntityFieldMapping,
    },
  ),
})

/**
 */
const validKeys = [
  EntityFields.Company,
  EntityFields.Division,
  EntityFields.FirstName,
  EntityFields.LastName,
  EntityFields.PhoenticFirstName,
  EntityFields.PhoenticLastName,
  EntityFields.PhoneNumber,
  EntityFields.TypeOfBusiness,
  EntityFields.JobTitle,
  EntityFields.IsIndividual,
]

/**
 */
const validAddressKeys = [
  EntityFields.City,
  EntityFields.StateOrPrefecture,
  EntityFields.Street,
  EntityFields.Building,
  EntityFields.PostalCode,
]

/**
 */
export const entityToUpdate = (entity) => {
  const b = {
    ...ObjectHelper.filterProperties(entity, validKeys),
    ...ObjectHelper.filterProperties(entity.address, validAddressKeys),
  }

  return b
}
