import React from 'react'

import * as ResetPasswordConfirmForm from '@arch-log/webapp.modules/auth/components/ResetPasswordConfirmForm'
import * as Literals from './Literals'
import * as styles from './styles.module.scss'

/**
 */
export const Submit = () => {
  return (
    <ResetPasswordConfirmForm.Submit className={styles.FormButton}>
      <Literals.Submit />
    </ResetPasswordConfirmForm.Submit>
  )
}

/**
 */
export const Errors = () => {
  const { formatFormError } = Literals.useLiterals()

  return <ResetPasswordConfirmForm.Errors formatError={formatFormError} />
}
