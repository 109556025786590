import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormName, isPristine, isSubmitting, reset } from 'redux-form'
import { Reset as ResetButton } from '@arch-log/webapp.atomics/components/Form/Buttons'

/**
 */
const Connected = connect(
  // mapStateToProps
  (state, { form }) => ({
    pristine: isPristine(form)(state),
    submitting: isSubmitting(form)(state),
  }),
)(
  // Base Component
  ({ className, pristine, submitting, children = 'reset', ...props }) => {
    return (
      <ResetButton disabled={pristine || submitting} {...props}>
        {children}
      </ResetButton>
    )
  },
)

/**
 *
 */
export const Reset = ({ children, ...props }) => (
  <FormName>
    {({ form }) => (
      <Connected form={form} {...props}>
        {children}
      </Connected>
    )}
  </FormName>
)
