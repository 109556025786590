import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'
import * as MyAccount from '@arch-log/webapp.modules/account/components/Me'

import * as Visibility from './Visibility'
import * as LanguageNavi from '../LanguageNavi'

import * as Links from '@arch-log/webapp.shared/Links'
import * as Literals from './Literals'
import * as Styled from './Styled'
import * as DefaultComponents from './DefaultComponents'
import * as Layout from './Layout'

import * as styles from './styles.module.scss'

/**
 */
const Initializer = ({ children }) => (
  <Visibility.Initializer>{children}</Visibility.Initializer>
)
/**
 */
export const DropMenu = ({
  children,
  components: { Container = DefaultComponents.DropMenu } = {},
}) => {
  const { isVisible, hide } = React.useContext(Visibility.Context)

  if (!isVisible) {
    return null
  }

  return <Container onMouseLeave={() => hide()}>{children}</Container>
}

/**
 */
export const Toggle = ({
  components: { Button = DefaultComponents.ToggleButton } = {},
}) => {
  const { toggle } = React.useContext(Visibility.Context)

  return (
    <Button toggle={toggle}>
      <Styled.Toggle />
    </Button>
  )
}

export const SignOut = () => (
  <Links.Auth.ToLogout>
    <Styled.LogoutIcon />
    <Literals.Logout />
  </Links.Auth.ToLogout>
)

export const ToProject = () => (
  <Links.Project.ToTop>
    <Styled.ProjectIcon />
    <Literals.ProjectManagement />
  </Links.Project.ToTop>
)

export const ToSetting = ({
  components: {
    Layout: { MenuTextContainer = Layout.MenuTextContainer } = {},
  } = {},
}) => (
  <Links.ToAccountInfo>
    <Styled.SettingIcon />
    <MenuTextContainer>
      <Literals.AccountSetting />
      <MyAccount.Initializer>
        (<MyAccount.Properties.LastName /> <MyAccount.Properties.FirstName />)
      </MyAccount.Initializer>
    </MenuTextContainer>
  </Links.ToAccountInfo>
)

export const ToPluginDownload = () => (
  <Links.ToPluginDownload>
    <Styled.PluginDownloadIcon />
    <Literals.PluginDownload />
  </Links.ToPluginDownload>
)

export const ChangeLanguage = () => (
  <>
    <LanguageNavi.Default />
  </>
)

/**
 *
 */
export const Default = withDefaultRenderComponent(
  ({
    items = [
      <ToSetting key="setting"/>,
      <ToPluginDownload key="plugin"/>,
      <ChangeLanguage key="language"/>,
      <SignOut key="signout"/>,
    ],
    components: {
      Layout: {
        Container = Layout.Container,
        DropdownSection = Layout.Dropdown,
        Items = Layout.Items,
        Item = Layout.Item,
      } = {},
    } = {},
  }) => {
    const itemComponents = items.map((item, idx) => (
      <Item key={idx}>{item}</Item>
    ))

    return (
      <Initializer>
        <Container>
          <Toggle />
          <DropMenu>
            <DropdownSection>
              <Items>{itemComponents}</Items>
            </DropdownSection>
          </DropMenu>
        </Container>
      </Initializer>
    )
  },
)

/**
 *
 */
export const Logout = withDefaultRenderComponent(
  ({
    items = [<ChangeLanguage key="language"/>],
    components: {
      Layout: {
        Container = Layout.Container,
        DropdownSection = Layout.Dropdown,
        Items = Layout.Items,
        Item = Layout.Item,
      } = {},
    } = {},
  }) => {
    const itemComponents = items.map((item, idx) => (
      <Item key={idx}>{item}</Item>
    ))

    return (
      <Initializer>
        <Container>
          <Toggle />
          <DropMenu>
            <DropdownSection>
              <Items>{itemComponents}</Items>
            </DropdownSection>
          </DropMenu>
        </Container>
      </Initializer>
    )
  },
)
