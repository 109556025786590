"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
exports.__esModule = true;
exports.navigate = void 0;
var _gatsby = require("gatsby");
var _urlJoin = _interopRequireDefault(require("url-join"));
var _utils = require("./utils");
/**
 * Wrapped navigation with language
 */
const navigate = (p, lang = "ja", options = null) => {
  (0, _utils.log)(`Navigate to ${(0, _urlJoin.default)("/", lang, p)}`);
  if (options) return (0, _gatsby.navigate)((0, _urlJoin.default)("/", lang, p), options);else return (0, _gatsby.navigate)((0, _urlJoin.default)("/", lang, p));
};
exports.navigate = navigate;