import React from 'react'

import { Initializer as I18NInitializer } from 'modules/i18n/components/Initializer'
import { Initializer as AuthInitializer } from '@arch-log/webapp.modules/auth/components/Initializer'
import { Initializer as ConfigInitializer } from '@arch-log/webapp.modules/config/components/Initializer'
import { Layout as DefaultPageLayout } from 'layouts/DefaultPageLayout'
import { Page as PageLoader } from '@arch-log/webapp.atomics/components/Loader'

import * as LayoutSetting from './Setting'

import * as styles from './styles.module.scss'

/**
 *
 */
export const Container = (props) => (
  <LayoutSetting.Initializer>
    <BaseContainer {...props} />
  </LayoutSetting.Initializer>
)

const BaseContainer = ({ children, pageContext, ...props }) => {
  const setting = React.useContext(LayoutSetting.Context)
  return (
    <ConfigInitializer>
      <I18NInitializer
        language={props?.params?.language ?? 'en'}
        loading={() => <PageLoader>Loading localization</PageLoader>}
      >
        <AuthInitializer
          synchronous={true}
          loading={() => <PageLoader>Loading session</PageLoader>}
        >
          <DefaultPageLayout {...setting}>{children}</DefaultPageLayout>
        </AuthInitializer>
      </I18NInitializer>
    </ConfigInitializer>
  )
}

/**
 */
export default Container
