import React from 'react'

// Import de
import { reduxForm } from 'redux-form'
import { BaseForm } from '@arch-log/webapp.modules/utils/components/FormTemplate'

import * as actions from '../../actions'
import * as FormValidator from './Validator'

/**
 */
export const ReduxForm = reduxForm({
  form: 'auth_resetPassword',
  validate: FormValidator.validate,
})(BaseForm)

/**
 */
export const Initializer = ({ onSuccess, onFail, ...props }) => {
  return (
    <ReduxForm
      submitAsSideEffect={true}
      onSubmit={actions.resetPasswordRequestFormSubmit}
      onSubmitSuccess={(a) => {
        if (onSuccess) {
          onSuccess()
        }
      }}
      onSubmitFail={(e) => {
        if (onFail) {
          onFail()
        }
      }}
      {...props}
    />
  )
}
