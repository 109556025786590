import React from 'react'

import * as Visibility from './Visibility'
import * as styles from './styles.module.scss'

/**
 */
export const Container = ({ children, ...props }) => {
  return <div className={styles.Navi_Container}>{children}</div>
}

/**
 */
export const Dropdown = ({ children }) => (
  <div className={styles.Navi_Items}>{children}</div>
)

/**
 */
export const Items = ({ children }) => (
  <ul className={styles.Items_Container}>{children}</ul>
)

/**
 */
export const Item = ({ children }) => (
  <li className={styles.Item_Container}>{children}</li>
)

/**
 */
export const MenuTextContainer = ({ children }) => (
  <span className={styles.MenuText}>{children}</span>
)
