import {
  translated,
  useTranslation,
} from '@arch-log/webapp.lib/TranslationHelper'

export const Submit = translated('module.auth')('Form.Submit.Label')

export const useLiterals = () => {
  const { t } = useTranslation('module.auth')

  const formatFieldPlaceholder = (field, ...options) =>
    t(`Form.Fields.${field}.Placeholder`, ...options)

  const formatFormError = (key, ...options) => t(key, ...options)

  return { formatFieldPlaceholder, formatFormError }
}
