import * as ModelFields from '@arch-log/webapp.modules/account/model/fields'

/**
 */
export const Email = ModelFields.Email
export const EmailConfirm = `${ModelFields.Email}_confirm`
export const Company = ModelFields.Company
export const IsIndividual = ModelFields.IsIndividual
export const Division = ModelFields.Division
export const JobTitle = ModelFields.JobTitle
export const FirstName = ModelFields.FirstName
export const LastName = ModelFields.LastName
export const PhoenticFirstName = ModelFields.PhoenticFirstName
export const PhoenticLastName = ModelFields.PhoenticLastName
export const PhoneNumberFirst = ModelFields.PhoneNumberFirst
export const PhoneNumberSecond = ModelFields.PhoneNumberSecond
export const PhoneNumberThird = ModelFields.PhoneNumberThird
export const TypeOfBusiness = ModelFields.TypeOfBusiness
