import React from 'react'
import { Context } from './Context'

/**
 */
export const Provider = ({ account, children, ...props }) => (
  <Context.Provider value={{ account }} {...props}>
    {children}
  </Context.Provider>
)
