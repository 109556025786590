import React from 'react'

import { Context } from './Context'

/**
 */
const DefaultLoading = () => <>loading..</>

/**
 */
export const withEntry = (Component) =>
  function WithEntry({
    //
    loading: Loading = DefaultLoading,
    ...props
  }) {
    const { account } = React.useContext(Context)

    // pre loading
    if (!account) {
      return <Loading />
    }

    return <Component entry={account} {...props} />
  }
