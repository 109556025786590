import React from 'react'

import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'
import * as ModelFields from '@arch-log/webapp.modules/account/model/fields'

import { TypesOfBusiness } from '../../../consts'
import { withTranslation as withT } from '@arch-log/webapp.modules/i18n/components/withTranslation'
import { withEntry } from '../utils'

/**
 */
export const Company = withEntry(
  ({ entry, render: Renderer = Renderers.TextRenderer, ...props }) => {
    // extract value
    const { [ModelFields.Company]: value = null } = entry

    // render value
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const Division = withEntry(
  ({ entry, render: Renderer = Renderers.TextRenderer, ...props }) => {
    // extract value
    const { [ModelFields.Division]: value = null } = entry

    // render value
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const JobTitle = withEntry(
  ({ entry, render: Renderer = Renderers.TextRenderer, ...props }) => {
    // extract value
    const { [ModelFields.JobTitle]: value = null } = entry

    // render value
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const FirstName = withEntry(
  ({ entry, render: Renderer = Renderers.TextRenderer, ...props }) => {
    // extract value
    const { [ModelFields.FirstName]: value = null } = entry

    // render value
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const LastName = withEntry(
  ({ entry, render: Renderer = Renderers.TextRenderer, ...props }) => {
    // extract value
    const { [ModelFields.LastName]: value = null } = entry

    // render value
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const FirstNamePhonetic = withEntry(
  ({ entry, render: Renderer = Renderers.TextRenderer, ...props }) => {
    // extract value
    const { [ModelFields.PhoenticFirstName]: value = null } = entry

    // render value
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const LastNamePhonetic = withEntry(
  ({ entry, render: Renderer = Renderers.TextRenderer, ...props }) => {
    // extract value
    const { [ModelFields.PhoenticLastName]: value = null } = entry

    // render value
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const PostalCode = withEntry(
  ({
    entry,
    render: Renderer = Renderers.TextRenderer,
    formatter = (upper, lower, { delimiter = '-' } = {}) => {
      if (upper && lower) {
        return [upper, lower].join(delimiter)
      }
      return null
    },
    ...props
  }) => {
    // extract value
    const {
      [ModelFields.Address]: {
        [ModelFields.PostalCodeUpper]: upper = null,
        [ModelFields.PostalCodeLower]: lower = null,
      } = {},
    } = entry

    // render value
    return <Renderer value={formatter(upper, lower)} {...props} />
  },
)

/**
 */
export const StateOrPrefecture = withEntry(
  ({ entry, render: Renderer = Renderers.TextRenderer, ...props }) => {
    // extract value
    const {
      [ModelFields.Address]: {
        [ModelFields.StateOrPrefecture]: value = null,
      } = {},
    } = entry

    // render value
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const City = withEntry(
  ({ entry, render: Renderer = Renderers.TextRenderer, ...props }) => {
    // extract value
    const { [ModelFields.Address]: { [ModelFields.City]: value = null } = {} } =
      entry

    // render value
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const Street = withEntry(
  ({ entry, render: Renderer = Renderers.TextRenderer, ...props }) => {
    // extract value
    const {
      [ModelFields.Address]: { [ModelFields.Street]: value = null } = {},
    } = entry

    // render value
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const Building = withEntry(
  ({ entry, render: Renderer = Renderers.TextRenderer, ...props }) => {
    // extract value
    const {
      [ModelFields.Address]: { [ModelFields.Building]: value = null } = {},
    } = entry

    // render value
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const PhoneNumber = withEntry(
  ({
    entry,
    render: Renderer = Renderers.TextRenderer,
    formatter = (first, second, third, { delimiter = '-' } = {}) => {
      if (first && second && third) {
        return [first, second, third].join(delimiter)
      }
      return null
    },
    ...props
  }) => {
    // extract value
    const {
      [ModelFields.PhoneNumberFirst]: first = null,
      [ModelFields.PhoneNumberSecond]: second = null,
      [ModelFields.PhoneNumberThird]: third = null,
    } = entry

    // render value
    return <Renderer value={formatter(first, second, third)} {...props} />
  },
)

/**
 */
export const TypeOfBusiness = withT('module.account')(
  withEntry(
    ({ t, entry, render: Renderer = Renderers.TextRenderer, ...props }) => {
      // extract value
      const { [ModelFields.TypeOfBusiness]: value = null } = entry

      const a = TypesOfBusiness.find((v) => v.key == value)

      // render value
      return <Renderer value={t(`TypeOfBusiness.${a.value}`)} {...props} />
    },
  ),
)

/**
 */
export const Email = withEntry(
  ({ entry, render: Renderer = Renderers.TextRenderer, ...props }) => {
    // extract value
    const { [ModelFields.Email]: value = null } = entry

    // render value
    return <Renderer value={value} {...props} />
  },
)
