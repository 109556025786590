import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as selectors from '../selectors'
import * as actions from '../actions'

import { Provider } from './Provider'

/**
 *
 */
const DefaultLoading = () => <span>Initializing Authentication</span>

/**
 *
 */
const NoopHandler = () => {}

/**
 *
 */
export const Initializer = connect(
  // mapStateToProps
  (state) => ({
    isInitialized: selectors.selectIsInitialized(state),
    isAuthenticated: selectors.selectIsAuthenticated(state),
  }),
  // mapDispatchToProps
  (dispatch) =>
    bindActionCreators(
      {
        init: actions.init,
      },
      dispatch,
    ),
)(
  // BaseComponent
  ({
    //
    isInitialized,
    isAuthenticated,
    init,
    synchronous = false,
    children,
    loading: Loading = DefaultLoading,
    onAnonymous = NoopHandler,
    onAuthenticated = NoopHandler,
  }) => {
    React.useEffect(() => {
      if (!isInitialized) {
        init()
      }
    }, [])

    //
    React.useEffect(() => {
      if (isInitialized) {
        if (isAuthenticated && onAuthenticated) {
          onAuthenticated()
        } else if (!isAuthenticated && onAnonymous) {
          onAnonymous()
        }
      }
    }, [isInitialized])

    if(synchronous && !isInitialized) {
      return <Loading/>
    }

    return <Provider>{children}</Provider>
  },
)
