import React from 'react'

import { connect } from 'react-redux'

import * as selectors from '../selectors'
import { Context } from './Context'

/**
 */
export const Provider = connect(
  //mapStateToProps
  (state) => ({
    isAuthenticated: selectors.selectIsAuthenticated(state),
    uid: selectors.selectUserId(state),
  }),
)(
  // Base
  ({ isAuthenticated, uid, children }) => (
    <Context.Provider value={{ isAuthenticated, uid }}>{children}</Context.Provider>
  ),
)
