import React from 'react'

// Import de
import { reduxForm } from 'redux-form'
import { parse } from 'querystring'
import { BaseForm } from '@arch-log/webapp.modules/utils/components/FormTemplate'

import * as actions from '../../actions'
import * as FormValidator from './Validator'

/**
 */
export const ReduxForm = reduxForm({
  form: 'auth_resetPasswordConfirm',
  validate: FormValidator.validate,
})(BaseForm)

/**
 */
export const Initializer = ({ onSuccess, onFail, ...props }) => {
  const [token, setToken] = React.useState(null)

  React.useEffect(() => {
    const search = new URLSearchParams(window.location.search)

    setToken(search.get('t'))
  }, [])

  return (
    <ReduxForm
      submitAsSideEffect={true}
      onSubmit={(data, ...args) =>
        actions.resetPasswordConfirmFormSubmit(
          {
            ...data,
            token,
          },
          ...args,
        )
      }
      onSubmitSuccess={(a) => {
        if (onSuccess) {
          onSuccess()
        }
      }}
      onSubmitFail={(e) => {
        if (onFail) {
          onFail()
        }
      }}
      {...props}
    />
  )
}
