/**
 */
export const selectModule = (state) => state.module.account

/**
 */
export const selectMe = (state) => selectModule(state).me

/**
 */
export const selectIsInitialized = (state) => selectModule(state).isInitialized

/**
 */
export const selectMeEntry = (state) => selectMe(state).entry
