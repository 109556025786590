import React from 'react'

import { connect } from 'react-redux'
import * as selectors from '../selectors'

/**
 * Contents for anonymous only
 */
export const ForAnonymous = connect(
  // mapStateToProps
  (state) => ({
    isAuthenticated: selectors.selectIsAuthenticated(state),
  }),
)(
  // BaseComponent
  ({
    // props
    isAuthenticated,
    children,
    onAuthenticated = () => {},
  }) => {
    if (isAuthenticated) {
      onAuthenticated()
      return null
    }

    return <>{children}</>
  },
)
