// Form Validator
import * as FieldValidators from './Field/Validators'

import * as FieldInvalidations from './Field/Invalidations'
import * as FormInvalidations from './Invalidations'
import { getErrorMessage } from '@arch-log/webapp.modules/utils/components/FormTemplate/utils/FormHelper'

/**
 */
export const validate = (values) => {
  const fieldErrors = FieldValidators.validate(values).filter((v) => v)

  return Object.fromEntries(
    [...fieldErrors, getFormErrorFromFields(fieldErrors)].filter((v) => v),
  )
}

/**
 */
export const getFormErrorFromFields = (fieldErrors) => {
  if (fieldErrors.find(([_, msg]) => msg === FieldInvalidations.Missing)) {
    return ['_error', getErrorMessage(FormInvalidations.InvalidField)]
  }
}
