import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'
import { Default as FormContent } from './Form'

import * as Components from './Components'
import * as Layout from './Layout'

/**
 */
export const Default = withDefaultRenderComponent(
  ({
    components: {
      Title: titleComponents,
      Layout: {
        Container = Layout.Container,
        Header = Layout.Header,
        Body = Layout.Body,
      } = {},
    } = {},
    next,
  }) => {
    return (
      <Container>
        <Header>
          <Components.Title components={titleComponents} />
        </Header>
        <Body>
          <FormContent next={next} />
        </Body>
      </Container>
    )
  },
)
