import React from 'react'
//
import { connect } from 'react-redux'
//
import { FormName, isPristine, isSubmitting } from 'redux-form'

const Base = ({
  pristine,
  submitting,
  children = 'submit',
  dispatch,
  ...props
}) => (
  <button type="submit" disabled={pristine || submitting} {...props}>
    {children}
  </button>
)

const mapStateToProps = (state, { form }) => ({
  pristine: isPristine(form)(state),
  submitting: isSubmitting(form)(state),
})

const Connected = connect(mapStateToProps)(Base)

/**
 *
 */
export const Submit = ({ children, ...props }) => (
  <FormName>
    {({ form }) => (
      <Connected form={form} {...props}>
        {children}
      </Connected>
    )}
  </FormName>
)
