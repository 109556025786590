import React from 'react'

import * as Links from '@arch-log/webapp.shared/Links'
import * as Styled from './Styled'
import * as Literals from './Literals'

import * as styles from './styles.module.scss'

/**
 */
export const LinksContainer = ({
  components: { Wrapper = Styled.LinksContainer } = {},
  isVisible = true,
  className = styles.Link,
}) => {
  if (!isVisible) {
    return null
  }

  return (
    <Wrapper>
      <Links.ToTerms className={className}>
        <Literals.Terms />
      </Links.ToTerms>
      <Links.ToPrivacyPolicy className={className}>
        <Literals.PrivacyPolicy />
      </Links.ToPrivacyPolicy>
    </Wrapper>
  )
}
