import React from 'react'

import {
  translated,
  useTranslation,
} from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Email = translated('module.auth')('Field.Labels.Email')

/**
 */
export const Submit = translated('pages')(
  'ResetPasswordRequest.Flow.Steps.InputStep.Form.Submit',
)

/**
 */
export const Cancel = translated('pages')(
  'ResetPasswordRequest.Flow.Steps.InputStep.Form.Cancel',
)

/**
 */
export const useLiterals = () => {
  const { t } = useTranslation(['pages', 'module.auth'])

  const formatPlaceholder = (placeholder, ...options) => {
    return t(
      [
        `pages:Field.Placeholder.${placeholder}`,
        `module.auth:Field.Placeholder.${placeholder}`,
      ],
      ...options,
    )
  }

  const formatFormError = (key, ...options) => {
    return t([`pages:${key}`, `module.auth:${key}`], ...options)
  }

  const formatFieldError = (key, ...options) => {
    return t(
      [`pages:Form.Error.${key}`, `module.auth:Form.Error.${key}`],
      ...options,
    )
  }

  return { formatPlaceholder, formatFormError, formatFieldError }
}
