// @flow
import { handleActions } from 'redux-actions'

import { ACTION_PREFIX } from './consts'

export const initState = {
  isInitialized: false,
  me: {
    isLoaded: false,
    entry: null,
  },
}

/**
 * Root reducer
 */
export const reducers = () => ({
  account: handleActions(
    {
      INIT: {
        SUCCESS: (state, action) => ({
          ...state,
          isInitialized: true,
        }),
      },
      ME: {
        LOAD: {
          SUCCESS: (state, action) => ({
            ...state,
            me: {
              isLoaded: true,
              entry: action.payload.data,
            },
          }),
        },
        UPDATE: {
          SUCCESS: (state, action) => ({
            ...state,
          }),
        },
        UPDATE_EMAIL: {
          SUCCESS: (state, action) => ({
            ...state,
          }),
        },
        UNLOAD: (state, action) => ({
          ...state,
          me: {
            isLoaded: false,
            entry: null,
          },
        }),
      },
    },
    initState,
    {
      prefix: ACTION_PREFIX,
    },
  ),
})
