import * as AddressFormHelper from '@arch-log/webapp.modules/address/components/Form/Helper'

import * as FieldNames from './FieldNames'
import * as ModelFields from '../../model/fields'
import { fieldValueOf } from '@arch-log/webapp.modules/utils/entity/Helper'

/**
 */
export const modelToForm = (model) => {
  return {
    ...AddressFormHelper.modelToForm(model.address),
    ...model,
  }
}

/**
 */
export const formToModel = (form) => {
  return {
    [ModelFields.Email]: fieldValueOf(form, FieldNames.Email),
    [ModelFields.IsIndividual]: fieldValueOf(form, FieldNames.IsIndividual),
    [ModelFields.Company]:
      fieldValueOf(form, FieldNames.IsIndividual) === true
        ? ''
        : fieldValueOf(form, FieldNames.Company),
    [ModelFields.Division]:
      fieldValueOf(form, FieldNames.IsIndividual) === true
        ? ''
        : fieldValueOf(form, FieldNames.Division),
    [ModelFields.FirstName]: fieldValueOf(form, FieldNames.FirstName),
    [ModelFields.LastName]: fieldValueOf(form, FieldNames.LastName),
    [ModelFields.PhoenticFirstName]: fieldValueOf(
      form,
      FieldNames.PhoenticFirstName,
    ),
    [ModelFields.PhoenticLastName]: fieldValueOf(
      form,
      FieldNames.PhoenticLastName,
    ),
    [ModelFields.PhoneNumberFirst]: fieldValueOf(
      form,
      FieldNames.PhoneNumberFirst,
    ),
    [ModelFields.PhoneNumberSecond]: fieldValueOf(
      form,
      FieldNames.PhoneNumberSecond,
    ),
    [ModelFields.PhoneNumberThird]: fieldValueOf(
      form,
      FieldNames.PhoneNumberThird,
    ),
    [ModelFields.TypeOfBusiness]: fieldValueOf(form, FieldNames.TypeOfBusiness),
    [ModelFields.JobTitle]:
      fieldValueOf(form, FieldNames.IsIndividual) === true
        ? ''
        : fieldValueOf(form, FieldNames.JobTitle),
    [ModelFields.Address]: AddressFormHelper.formToModel(form),
  }
}
