import React from 'react'

import { Field } from 'redux-form'

import * as FieldRenderers from './Field/Renderers'
import * as FieldNames from './FieldNames'
import * as Inputs from './Field/Inputs'

/**
 */
export const Password = ({
  render: Renderer = FieldRenderers.Password,
  ...props
}) => {
  return (
    <Field
      name={FieldNames.Password}
      component={Renderer}
      components={{ Input: Inputs.Password }}
      placeholder="Password"
      {...props}
    />
  )
}

/**
 */
export const PasswordConfirm = ({
  render: Renderer = FieldRenderers.PasswordConfirm,
  ...props
}) => {
  return (
    <Field
      name={FieldNames.PasswordConfirm}
      component={Renderer}
      components={{ Input: Inputs.PasswordConfirm }}
      placeholder="PasswordConfirm"
      {...props}
    />
  )
}
