import { defaultClient as httpClient } from '../utils/http'
import {
  entityToModel,
  modelToEntity,
  entityToUpdate,
} from './entity/converters'

import * as EntityFields from './entity/fields'
import * as FormInvalidation from './components/ChangeEmailForm/Invalidations'

/**
 */
export const UpdateMe = async (data) => {
  const formData = new URLSearchParams()

  Object.entries(entityToUpdate(modelToEntity(data))).forEach(
    ([key, value]) => {
      if ((value ?? null) !== null) {
        formData.append(key, value ?? null)
      }
    },
  )

  return httpClient
    .post('/user/edit', formData)
    .then(({ data }) => data)
    .then(({ code, data = null, message = null }) => {
      if (code !== 0) {
        throw { code, message }
      }
      return data
    })
}

/**
 */
export const changeEmail = async (data) => {
  const formData = new URLSearchParams()

  formData.append(EntityFields.Email, data.email)

  return httpClient
    .post('/user/edit', formData)
    .then(({ data }) => data)
    .then(({ code, data = null, message = null }) => {
      if (code !== 0) {
        switch (code) {
          case 2:
            throw FormInvalidation.EmailAlreadyRegistererd
          default:
            throw FormInvalidation.SubmitFailed
        }
      }
      return data
    })
}

/**
 */
export const loadMe = async () => {
  return httpClient
    .post('/user/details')
    .then(({ data }) => data)
    .then(({ code, data = null, message = null }) => {
      if (code !== 0) {
        throw { code, message }
      }
      return data
    })
    .then((data) => entityToModel(data))
}
