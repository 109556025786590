import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'
import {
  ForAnonymous,
  Authenticated,
} from '@arch-log/webapp.modules/auth/components'

import * as Containers from './Containers'
import * as Layout from './Layout'
import * as Menu from './Menu'

/**
 */
export const Default = withDefaultRenderComponent(
  ({
    enableSearch = true,
    enableLogoLink = true,
    enableSignIn = true,
    enableSignUp = true,
    showProjectLink = true,
    onSearch,
    defaultSearchKeyword,
    components: {
      Logo: logoComponents,
      Search: searchComponents,
      LanguageNavi: languageNaviComponents,
      Menu: menuComponents,
      Layout: {
        Container = Layout.Container,
        LogoSection = Layout.Logo,
        SearchSection = Layout.Search,
        LanguageNaviSection = Layout.LanguageNavi,
        ProjectSection = Layout.Project,
        SignUpSection = Layout.SignUp,
        SignInSection = Layout.SignIn,
        MenuSection = Layout.Menu,
        SpaceSection = Layout.Space,
      } = {},
    } = {},
  }) => {
    return (
      <Container>
        <LogoSection>
          <Containers.Logo
            components={logoComponents}
            canNavigate={enableLogoLink}
          />
        </LogoSection>
        <SearchSection 
            isEnabled={enableSearch}
        >
          <Containers.Search
            isEnabled={enableSearch}
            components={searchComponents}
            onSearch={onSearch}
            defaultKeyword={defaultSearchKeyword}
          />
        </SearchSection>
        <SpaceSection/>
        <ForAnonymous>
          <SignUpSection isVisible={enableSignUp}>
            <Containers.SignUp />
          </SignUpSection>
          <SignInSection isVisible={enableSignIn}>
            <Containers.SignIn />
          </SignInSection>
          <MenuSection>
            <Menu.Logout components={menuComponents} />
          </MenuSection>
        </ForAnonymous>
        <Authenticated>
          <ProjectSection isVisible={showProjectLink}>
            <Containers.ToProject />
          </ProjectSection>
          <MenuSection>
            <Menu.Default components={menuComponents} />
          </MenuSection>
        </Authenticated>
      </Container>
    )
  },
)
