/**
 */
export const Address = 'address'
export const Company = 'company'
export const Division = 'division'
export const Email = 'email'
export const FirstName = 'first_name'
export const Id = 'id'
export const IsIndividual = 'individual'
export const JobTitle = 'title'
export const LastName = 'last_name'
export const PhoenticFirstName = 'phonetic_first_name'
export const PhoenticLastName = 'phonetic_last_name'
export const PhoneNumber = 'phone_number'
export const TypeOfBusiness = 'industry'
export const PostalCode = 'postal_code'
export const StateOrPrefecture = 'state'
export const City = 'city'
export const Street = 'address'
export const Building = 'address_2'
