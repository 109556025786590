// @flow
import { handleActions } from 'redux-actions'

import { ACTION_PREFIX } from './consts'

export const initState = {
  isInitialized: false,
  isAuthenticated: false,
  uid: null,
  token: null,
  errors: [],
}

/**
 * Root reducer
 */
export const reducers = () => ({
  auth: handleActions(
    {
      INIT: {
        SUCCESS: (state, action) => ({
          ...state,
          isInitialized: true,
        }),
      },
      AUTHENTICATED: (state, action) => ({
        ...state,
        isAuthenticated: true,
        uid: action?.payload?.uid,
      }),
      LOGIN: {
        SUCCESS: (state, action) => ({
          ...state,
        }),
        FAILURE: (state, action) => ({
          ...state,
          errors: [...state.errors, action.payload.error],
        }),
      },
      LOGOUT: {
        REQUEST: (state, action) => ({
          ...state,
          isAuthenticated: false,
          token: null,
        }),
      },
      CHANGE_PASSWORD: {
        SUCCESS: (state, action) => ({
          ...state,
        }),
      },
      RESET_PASSWORD: {
        SUCCESS: (state, action) => ({
          ...state,
        }),
      },
      RESET_PASSWORD_CONFIRM: {
        SUCCESS: (state, action) => ({
          ...state,
        }),
      },
      CLEAR_ERRORS: (state, action) => ({
        ...state,
        errors: [],
      }),
    },
    initState,
    {
      prefix: ACTION_PREFIX,
    },
  ),
})
