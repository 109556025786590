import React from 'react'

import * as Links from '@arch-log/webapp.shared/Links'
import * as styles from './styles.module.scss'

/**
 */
export const MessageWrapper = ({ children, ...props }) => {
  return (
    <p className={styles.Message} {...props}>
      {children}
    </p>
  )
}

/**
 */
export const CompleteButton = ({ children, ...props }) => {
  return (
    <Links.Auth.ToLogin className={styles.CompleteButton} {...props}>
      {children}
    </Links.Auth.ToLogin>
  )
}
