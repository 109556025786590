import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'

import * as I18n from '@arch-log/webapp.modules/i18n/components'
import * as Styled from './Styled'
import * as Literals from './Literals'
import * as styles from './styles.module.scss'

import { Items, Item } from '../Menu'
import { ChangeLanguage } from '@arch-log/webapp.shared/Language/Actions'

/**
 */
export const Default = withDefaultRenderComponent((props) => (
  <I18n.Languages.Active
    render={({ language, ...params }) => {
      switch (language) {
        case 'ja':
          return (
            <ChangeLanguage
              render={({ changeLanguage }) => (
                <a onClick={() => changeLanguage('en')}>
                  <Styled.LanguageIcon />
                  <Literals.ChangeToEnglish />
                </a>
              )}
            />
          )
        case 'en':
          return (
            <ChangeLanguage
              render={({ changeLanguage }) => (
                <a onClick={() => changeLanguage('ja')}>
                  <Styled.LanguageIcon />
                  <Literals.ChangeToJapanese />
                </a>
              )}
            />
          )
        default:
          return null
      }
    }}
  />
))

//{
//
//
//  return (
//    <dl className={styles.Navi_Container}>
//      <dt className={styles.Navi_Current}>
//      </dt>
//    </dl>
//  )
//}
//
