import React from 'react'

import * as Layout from './Layout'
import * as Components from './Components'

/**
 */
export const Default = ({
  components: {
    Message: messageComponents,
    Complete: completeComponents,
    Layout: {
      Container = Layout.Container,
      Body = Layout.Body,
      Footer = Layout.Footer,
    } = {},
  } = {},
  onComplete,
}) => {
  return (
    <Container>
      <Body>
        <Components.Message components={messageComponents} />
      </Body>
      <Footer>
        <Components.Complete components={completeComponents} />
      </Footer>
    </Container>
  )
}
