import React from 'react'

import * as Visibility from './Visibility'
import * as styles from './styles.module.scss'

/**
 */
export const ToggleButton = ({ toggle, children }) => (
  <div className={styles.Navi_Trigger} onClick={() => toggle()}>
    {children}
  </div>
)

export const DropMenu = ({ children, ...props }) => (
  <div {...props}>{children}</div>
)
