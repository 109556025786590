import * as ModelFields from '@arch-log/webapp.modules/address/model/fields'
import * as FieldNames from './FieldNames'

const fieldValueOf = (obj, field, defaultValue = null) => {
  return obj[field] ?? defaultValue
}

/**
 */
export const modelToForm = (model) => ({
  [FieldNames.PostalCodeUpper]: fieldValueOf(
    model,
    ModelFields.PostalCodeUpper,
  ),
  [FieldNames.PostalCodeLower]: fieldValueOf(
    model,
    ModelFields.PostalCodeLower,
  ),
  [FieldNames.StateOrPrefecture]: fieldValueOf(
    model,
    ModelFields.StateOrPrefecture,
  ),
  [FieldNames.City]: fieldValueOf(model, ModelFields.City),
  [FieldNames.Street]: fieldValueOf(model, ModelFields.Street),
  [FieldNames.Building]: fieldValueOf(model, ModelFields.Building),
})

/**
 */
export const formToModel = (data) => ({
  [ModelFields.PostalCodeUpper]: fieldValueOf(data, FieldNames.PostalCodeUpper),
  [ModelFields.PostalCodeLower]: fieldValueOf(data, FieldNames.PostalCodeLower),
  [ModelFields.StateOrPrefecture]: fieldValueOf(
    data,
    FieldNames.StateOrPrefecture,
  ),
  [ModelFields.City]: fieldValueOf(data, FieldNames.City),
  [ModelFields.Street]: fieldValueOf(data, FieldNames.Street),
  [ModelFields.Building]: fieldValueOf(data, FieldNames.Building),
})
