import React from 'react'

import * as Layout from '@arch-log/webapp.atomics/layouts/FormLayout'
import * as styles from './styles.module.scss'

/**
 */
export const Field = ({ children }) => {
  return <Layout.Field className={styles.Field}>{children}</Layout.Field>
}

/**
 */
export const Label = ({ children }) => {
  return <Layout.Label className={styles.FieldLabel}>{children}</Layout.Label>
}

/**
 */
export const Input = ({ children }) => {
  return <Layout.Input className={styles.FieldInput}>{children}</Layout.Input>
}
