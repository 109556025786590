import React from 'react'

import * as ResetPasswordRequestForm from '@arch-log/webapp.modules/auth/components/ResetPasswordRequestForm'
import * as Components from './Components'
import * as Fields from './Fields'
import * as Layout from './Layout'

/**
 */
export const Default = ({
  components: {
    Layout: {
      Container = Layout.Container,
      Header = Layout.Header,
      Body = Layout.Body,
      Footer = Layout.Footer,
    } = {},
  } = {},
  next,
}) => {
  return (
    <Container>
      <ResetPasswordRequestForm.Initializer onSuccess={() => next()}>
        <Header>
          <Components.Errors />
        </Header>
        <Body>
          <Fields.Email />
        </Body>
        <Footer>
          <Components.Submit />
          <Components.Cancel />
        </Footer>
      </ResetPasswordRequestForm.Initializer>
    </Container>
  )
}
