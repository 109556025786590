// extracted by mini-css-extract-plugin
export var Body = "styles-module--Body--A3RP1";
export var Cancel = "styles-module--Cancel--ZZRvW";
export var Container = "styles-module--Container--1Ltfw";
export var Error = "styles-module--Error--3rdzV";
export var Field = "styles-module--Field--Ecm2y";
export var FieldInput = "styles-module--FieldInput--SnK5v";
export var FieldInputItem = "styles-module--FieldInputItem--m2XIk";
export var FieldLabel = "styles-module--FieldLabel--swL6s";
export var Footer = "styles-module--Footer--6irIB";
export var FormButton = "styles-module--FormButton--XTlyS";