import React from 'react'

import { connect } from 'react-redux'

import * as selectors from '../../selectors'

/**
 */
const DefaultFormatter = (errors) => {
  const messages = errors.map((error, idx) => (
    <span key={idx}>{error.message}</span>
  ))

  return <div>{messages}</div>
}

/**
 */
export const Error = connect((state) => ({
  errors: selectors.selectErrors(state),
}))(({ errors, format = DefaultFormatter }) => {
  if (!errors) {
    return null
  }

  return <>{format(errors)}</>
})
