// extracted by mini-css-extract-plugin
export var Container = "styles-module--Container--1g5gR";
export var InProjectContainer = "styles-module--InProjectContainer--Lt9uF";
export var Languages = "styles-module--Languages--grEBx";
export var Link = "styles-module--Link--JdcJb";
export var LinkToProject = "styles-module--LinkToProject--zNKNR";
export var LinkToSignIn = "styles-module--LinkToSignIn--PnVT0";
export var LinkToSignUp = "styles-module--LinkToSignUp--aI0bx";
export var LoginIcon = "styles-module--LoginIcon--lpj11";
export var Logo = "styles-module--Logo--i9+fJ";
export var LogoProject = "styles-module--LogoProject--rWGxZ";
export var Logo_Image = "styles-module--Logo_Image--IqiBC";
export var Menu = "styles-module--Menu--vEv2P";
export var Project = "styles-module--Project--HjytI";
export var ProjectIcon = "styles-module--ProjectIcon--IQBZ-";
export var ProjectText = "styles-module--ProjectText--4SQY8";
export var Search = "styles-module--Search--bGJ4A";
export var SignIn = "styles-module--SignIn--a5+0-";
export var SignUp = "styles-module--SignUp--UJmZ7";
export var Space = "styles-module--Space--OulTL";