/**
 */
export const Address = 'address'
export const Company = 'company'
export const Division = 'division'
export const Email = 'email'
export const FirstName = 'first_name'
export const Id = 'id'
export const IsIndividual = 'is_individual'
export const JobTitle = 'job_title'
export const LastName = 'last_name'
export const PhoenticFirstName = 'first_name_phonetic'
export const PhoenticLastName = 'last_name_phonetic'
export const PhoneNumberFirst = 'phonenumber_first'
export const PhoneNumberSecond = 'phonenumber_second'
export const PhoneNumberThird = 'phonenumber_third'
export const TypeOfBusiness = 'type_of_business'

export {
  PostalCodeUpper,
  PostalCodeLower,
  StateOrPrefecture,
  City,
  Street,
  Building,
} from '@arch-log/webapp.modules/address/model/fields'
