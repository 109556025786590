import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'

import * as Literals from './Literals'
import * as Styled from './Styled'

/**
 */
export const Title = withDefaultRenderComponent(
  ({ components: { Wrapper = Styled.TitleWrapper } = {} }) => {
    return (
      <Wrapper>
        <Literals.Title />
      </Wrapper>
    )
  },
)
