import {
  Invalidations,
  Validators as Requirements,
} from '@arch-log/webapp.lib/form/validation'

import * as FieldInvalidations from './Invalidations'
import * as FieldNames from '../FieldNames'

/**
 */
export const validate = (values) => {
  return [validatePassword(values), validatePasswordConfirm(values)]
}

/**
 */
const validatePassword = (values) => {
  try {
    Requirements.Required(values[FieldNames.Password])
    Requirements.Pattern.PasswordLength(values[FieldNames.Password])
    Requirements.Pattern.IncludeAlphabetLowerCase(values[FieldNames.Password])
    Requirements.Pattern.IncludeAlphabetUpperCase(values[FieldNames.Password])
    Requirements.Pattern.IncludeNumeral(values[FieldNames.Password])
    Requirements.Pattern.IncludeSymbol(values[FieldNames.Password])
  } catch (err) {
    switch (err) {
      case Invalidations.Missing:
        return [FieldNames.Password, FieldInvalidations.Missing]
      case Invalidations.InvalidLength:
        return [FieldNames.Password, FieldInvalidations.InvalidPasswordLength]
      case Invalidations.MissingLowerCase:
        return [
          FieldNames.Password,
          FieldInvalidations.MissingPasswordLowerCase,
        ]
      case Invalidations.MissingUpperCase:
        return [
          FieldNames.Password,
          FieldInvalidations.MissingPasswordUpperCase,
        ]
      case Invalidations.MissingNumeral:
        return [FieldNames.Password, FieldInvalidations.MissingPasswordNumeral]
      case Invalidations.MissingSymbol:
        return [FieldNames.Password, FieldInvalidations.MissingPasswordSymbol]
      default:
        return null
    }
  }
}

/**
 */
const validatePasswordConfirm = (values) => {
  try {
    Requirements.Required(values[FieldNames.PasswordConfirm])
    Requirements.Conditional.Equal(
      values[FieldNames.PasswordConfirm],
      values[FieldNames.Password],
    )
  } catch (err) {
    switch (err) {
      case Invalidations.Missing:
        return [FieldNames.PasswordConfirm, FieldInvalidations.Missing]
      case Invalidations.ConditionalMismatch:
        return [FieldNames.PasswordConfirm, FieldInvalidations.Mismatch]
      default:
        return null
    }
  }
}
