import React from 'react'

import { Field, Label, Input } from './Styled'

import * as ResetPasswordConfirmForm from '@arch-log/webapp.modules/auth/components/ResetPasswordConfirmForm'
import * as Literals from './Literals'
import * as styles from './styles.module.scss'

/**
 */
export const Password = () => {
  const { formatPlaceholder, formatFieldError } = Literals.useLiterals()

  return (
    <Field>
      <Label>
        <Literals.Password />
      </Label>
      <Input>
        <ResetPasswordConfirmForm.Fields.Password
          className={styles.FieldInputItem}
          formatPlaceholder={formatPlaceholder}
          formatError={formatFieldError}
        />
      </Input>
    </Field>
  )
}

/**
 */
export const PasswordConfirm = () => {
  const { formatPlaceholder, formatFieldError } = Literals.useLiterals()

  return (
    <Field>
      <Label>
        <Literals.PasswordConfirm />
      </Label>
      <Input>
        <ResetPasswordConfirmForm.Fields.PasswordConfirm
          className={styles.FieldInputItem}
          formatPlaceholder={formatPlaceholder}
          formatError={formatFieldError}
        />
      </Input>
    </Field>
  )
}
