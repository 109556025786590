import React from 'react'

import * as Containers from './Containers'
import * as Layout from './Layout'

export const Default = ({
  children,
  enableSearch = true,
  enableLogoLink = true,
  enableSignIn = true,
  enableSignUp = true,
  showFooter = true,
  showFooterLinks = true,
  showProjectLink = true,
  components: {
    Header: headerComponents,
    Footer: footerComponents,
    Layout: {
      Wrapper = Layout.Wrapper,
      HeaderSection = Layout.Header,
      BodySection = Layout.Body,
      FooterSection = Layout.Footer,
    } = {},
  } = {},
  ...props
}) => (
  <Wrapper>
    <HeaderSection>
      <Containers.Header
        components={headerComponents}
        enableSearch={enableSearch}
        enableLogoLink={enableLogoLink}
        enableSignIn={enableSignIn}
        enableSignUp={enableSignUp}
        showProjectLink={showProjectLink}
      />
    </HeaderSection>
    <BodySection>{children}</BodySection>
    <FooterSection>
      {showFooter ? (
        <Containers.Footer
          components={footerComponents}
          showLinks={showFooterLinks}
          enableLogoLink={enableLogoLink}
        />
      ) : null}
    </FooterSection>
  </Wrapper>
)
