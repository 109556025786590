import React from 'react'

import { connect } from 'react-redux'
import { FormName, getFormError, hasSubmitFailed } from 'redux-form'

import * as FormErrors from '@arch-log/webapp.atomics/components/Form/Errors'

/**
 */
const ConnectedErrors = connect(
  // mapStateToProps,
  (state, { form }) => ({
    formError: getFormError(form)(state),
    submitFailed: hasSubmitFailed(form)(state),
  }),
  // mapDispatchToProps
  null,
)(
  // BaseComponent
  ({
    components: {
      Errors = FormErrors.Container,
      Error = FormErrors.Error,
    } = {},
    formError,
    submitFailed,
    formatError = (t) => t,
    ...props
  }) => {
    if (submitFailed && formError) {
      return (
        <Errors>
          <Error formatMessage={formatError} {...props}>
            {formError}
          </Error>
        </Errors>
      )
    }

    return null
  },
)

/**
 */
export const Errors = (props) => (
  <FormName>
    {({ form }) => <ConnectedErrors form={form} {...props} />}
  </FormName>
)
