import React from 'react'


import * as BaseHeader from '@arch-log/webapp.shared/GlobalHeader'
import * as BaseFooter from '@arch-log/webapp.shared/GlobalFooter'

/**
 * Header Container
 */
export const Header = BaseHeader.Default

/**
 * Footer Container
 */
export const Footer = BaseFooter.Default

/**
 * Header in project Container
 */
export const HeaderInProject = BaseHeader.InProject
