import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'

import * as ResetPasswordConfirmForm from '@arch-log/webapp.modules/auth/components/ResetPasswordConfirmForm'
import * as Components from './Components'
import * as Fields from './Fields'
import * as Layout from './Layout'

/**
 */
export const Default = withDefaultRenderComponent(
  ({
    components: {
      Layout: {
        Container = Layout.Container,
        Header = Layout.Header,
        Body = Layout.Body,
        Footer = Layout.Footer,
      } = {},
    } = {},
    next,
  }) => {
    return (
      <Container>
        <ResetPasswordConfirmForm.Initializer onSuccess={() => next()}>
          <Header>
            <Components.Errors />
          </Header>
          <Body>
            <Fields.Password />
            <Fields.PasswordConfirm />
          </Body>
          <Footer>
            <Components.Submit />
          </Footer>
        </ResetPasswordConfirmForm.Initializer>
      </Container>
    )
  },
)
