import React from 'react'

import { connect } from 'react-redux'
import { navigate } from 'gatsby'

import { selectIsAuthenticated } from '../selectors'

/**
 */
export const PrivateRoute = connect(
  // mapStateToProps
  (state) => ({
    isAuthenticated: selectIsAuthenticated(state),
  }),
)(
  // BaseComponent
  ({
    //
    isAuthenticated,
    onAnonymous,
    component: Component,
    ...rest
  }) => {
    if (!isAuthenticated) {
      if (onAnonymous) {
        onAnonymous()
      }
      return null
    }

    return <Component {...rest} />
  },
)
