import React from 'react'

import * as Layout from './Layout'
import * as Containers from './Containers'

export const Default = ({
  showResetPassword = true,
  showRegistration = true,
  components: { Layout: { Wrapper = Layout.Wrapper } = {} } = {},
}) => (
  <Wrapper>
    <Containers.ForgotPassowrd isVisible={showResetPassword} />
    <Containers.horizontalLine isVisible={showRegistration} />
    <Containers.Registration isVisible={showRegistration} />
  </Wrapper>
)
