import React from 'react'

import { Copyright as BaseCopyright } from '@arch-log/webapp.shared/Copyright'
import * as Navi from './Navi'
import * as Logo from './Logo'
import * as styles from './styles.module.scss'

/**
 *
 */
const DefaultContainer = ({ children }) => (
  <footer className={styles.Container}>{children}</footer>
)

/**
 */
const DefaultCopyright = () => (
  <div className={styles.Copyright}>
    <BaseCopyright />
  </div>
)

/**
 */
const DefaultNavi = ({ isVisible = true }) => {
  if (!isVisible) {
    return null
  }

  return <Navi.Default />
}

/**
 */
const DefaultLogo = () => <Logo.Default />

/**
 */
const DefaultLogoAndNaviContainer = ({ children }) => (
  <div className={styles.LogoAndNaviContainer}>{children}</div>
)

/**
 *
 */
export const Default = ({
  showLinks = true,
  components: {
    Container = DefaultContainer,
    LogoAndNaviContainer = DefaultLogoAndNaviContainer,
    Copyright = DefaultCopyright,
    Logo = DefaultLogo,
    Navi = DefaultNavi,
  } = {},
}) => (
  <Container>
    <LogoAndNaviContainer>
      <Logo />
      <Navi isVisible={showLinks} />
    </LogoAndNaviContainer>
    <Copyright />
  </Container>
)
