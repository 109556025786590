import { translated, useTranslation } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const ForgotPassword = translated('module.auth')('ForgotPassword')

/**
 */
export const Registration = translated('module.auth')('Registration')

/**
 */
export const useLiterals = () => {
    const { t } = useTranslation('module.auth')

    const signUpText = () => {
        return t('SignUp')
    }

    return {
        signUpText,
    }
}
