import React from 'react'

import * as styles from './styles.module.scss'
import { MarubeniArchLog as LogoImage } from '@arch-log/webapp.shared/Logo/Image'

/**
 */
export const Default = () => (
  <div className={styles.Logo}>
    <LogoImage className={styles.Logo_Image} />
  </div>
)
