import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as actions from '../actions'
import * as selectors from '../selectors'

const DefaultLoading = () => <span>Processing logout...</span>

/**
 */
export const Logout = connect(
  (state) => ({
    isAuthenticated: selectors.selectIsAuthenticated(state),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        logout: actions.logout,
      },
      dispatch,
    ),
)(
  // BaseComponent
  class Base extends React.Component {
    static defaultProps = {
      onComplete: () => {},
      loading: DefaultLoading,
    }

    componentDidMount = () => {
      const { isAuthenticated, logout, onComplete } = this.props

      if (isAuthenticated) {
        logout({ onComplete })
      } else {
        onComplete()
      }
    }

    //componentDidUpdate = () => {
    //  const { isAuthenticated, onComplete } = this.props

    //  if (!isAuthenticated) {
    //    onComplete()
    //  }
    //}

    render = () => {
      const { children, isAuthenticated, loading: Loading } = this.props

      if (isAuthenticated) {
        return <Loading />
      }

      return <>{children}</>
    }
  },
)
