import { combineReducers } from 'redux'

import { reducers as accountReducers } from '@arch-log/webapp.modules/account/reducers'
import { reducers as authReducers } from '@arch-log/webapp.modules/auth/reducers'
import { reducers as i18nReducers } from '@arch-log/webapp.modules/i18n/reducers'

export const reducers = () => ({
  module: combineReducers({
    ...accountReducers(),
    ...authReducers(),
    ...i18nReducers(),
  }),
})
