"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
exports.__esModule = true;
exports.onClientEntry = void 0;
var _reactGtmModule = _interopRequireDefault(require("react-gtm-module"));
const onClientEntry = () => {
  if (process.env.GTM_ID) {
    _reactGtmModule.default.initialize({
      gtmId: process.env.GTM_ID
    });
  }
};
exports.onClientEntry = onClientEntry;