"use strict";

exports.__esModule = true;
var _Context = require("./Context");
Object.keys(_Context).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _Context[key]) return;
  exports[key] = _Context[key];
});
var _Provider = require("./Provider");
Object.keys(_Provider).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _Provider[key]) return;
  exports[key] = _Provider[key];
});