import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'
import {
  ForAnonymous,
  Authenticated,
} from '@arch-log/webapp.modules/auth/components'

import * as Containers from './Containers'
import * as Layout from './Layout'
import * as Menu from './Menu'

/**
 */
export const InProject = withDefaultRenderComponent(
  ({
    enableSearch = true,
    enableLogoLink = true,
    onSearch,
    defaultSearchKeyword,
    components: {
      Logo: logoComponents,
      Search: searchComponents,
      LanguageNavi: languageNaviComponents,
      Menu: menuComponents,
      Layout: {
        Container = Layout.InProjectContainer,
        LogoSection = Layout.Logo,
        LogoProject = Layout.LogoProject,
        LanguageNaviSection = Layout.LanguageNavi,
        SignUpSection = Layout.SignUp,
        SignInSection = Layout.SignIn,
        MenuSection = Layout.Menu,
        SpaceSection = Layout.Space,
      } = {},
    } = {},
  }) => (
    <Container>
      <LogoSection>
        <Containers.Logo
          components={logoComponents}
          canNavigate={enableLogoLink}
        />
        <LogoProject />
      </LogoSection>
      <SpaceSection/>
      <ForAnonymous>
        <SignUpSection>
          <Containers.SignUp />
        </SignUpSection>
        <SignInSection>
          <Containers.SignIn />
        </SignInSection>
        <MenuSection>
          <Menu.Logout components={menuComponents} />
        </MenuSection>
      </ForAnonymous>
      <Authenticated>
        <MenuSection>
          <Menu.Default components={menuComponents} />
        </MenuSection>
      </Authenticated>
    </Container>
  ),
)
