import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'

import * as Literals from './Literals'
import * as Styled from './Styled'

/**
 */
export const Message = withDefaultRenderComponent(
  ({ components: { Wrapper = Styled.MessageWrapper } = {} }) => (
    <Wrapper>
      <Literals.Message />
    </Wrapper>
  ),
)

/**
 */
export const Complete = withDefaultRenderComponent(
  ({ components: { Button = Styled.CompleteButton } = {} }) => {
    return (
      <Button>
        <Literals.CompleteButton />
      </Button>
    )
  },
)
