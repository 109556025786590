import React from 'react'

import * as config from '@arch-log/webapp.modules/config/config'
//import * as Links from '@arch-log/webapp.shared/Links'

//import * as Icons from '@arch-log/webapp.shared/Icons'
//import * as Button from 'components/Button'
//import * as Texts from './Texts'

//import * as styles from './styles.module.scss'

/**
 * AuthenticatedLogout
 */
export const Content = () => {
  React.useEffect(() => {
    //
    window.location.href = config.get('auth.defaultRedirectUri')
  })
  return null
}

//(
//  <Container>
//    <Links.ToProductSearch>
//      <Texts.SearchProduct />
//    </Links.ToProductSearch>
//    {/*
//        <Button.FixedWidthThemeColorButton icon={<Icons.Search />}>
//          {t('Search Products')}
//        </Button.FixedWidthThemeColorButton>
//        */}
//
//    {/*
//          <Button.FixedWidthThemeColorButton icon={<Icons.Project />}>
//            { t('Manage Project') }
//          </Button.FixedWidthThemeColorButton>
//        */}
//
//    <Links.Auth.ToLogout>
//      <Texts.Logout />
//    </Links.Auth.ToLogout>
//  </Container>
//)
