import React from 'react'

import { connect } from 'react-redux'
import { FormName, isPristine, isSubmitting } from 'redux-form'
import { Submit as SubmitButton } from '@arch-log/webapp.atomics/components/Form/Buttons'

/**
 */
const DefaultRenderer = ({
  children = 'submit',
  canSubmit,
  form,
  ...props
}) => {
  return (
    <SubmitButton type="submit" form={form} disabled={!canSubmit} {...props}>
      {children}
    </SubmitButton>
  )
}

/**
 */
const BaseSubmit = connect(
  // mapStateToProps,
  (state, { form }) => ({
    pristine: isPristine(form)(state),
    submitting: isSubmitting(form)(state),
  }),
  // mapDispatchToProps
  null,
)(
  // BaseComponent
  ({
    render: Renderer = DefaultRenderer,
    canPrestineSubmit = true,
    pristine,
    submitting,
    dispatch,
    ...props
  }) => (
    <Renderer
      canSubmit={canPrestineSubmit ? !pristine && !submitting : !submitting}
      {...props}
    />
  ),
)

/**
 */
export const Submit = (props) => (
  <FormName>{({ form }) => <BaseSubmit form={form} {...props} />}</FormName>
)
