import React from 'react'

import * as PageHeadTemplates from '@arch-log/webapp.shared/Page/Head'
import * as Partial from 'partials/ResetPasswordConfirmPage'

/**
 */
export const Head = ({ pageContext }) => {
 return <PageHeadTemplates.Language language={pageContext?.language} />
}

/**
 */
const Page = () => {
  return <Partial.Flow />
}

/**
 */
export default Page
