import React from 'react'

import * as ResetPasswordRequestForm from '@arch-log/webapp.modules/auth/components/ResetPasswordRequestForm'
import * as Buttons from '@arch-log/webapp.shared/Buttons'
import * as Links from '@arch-log/webapp.shared/Links'
import * as Literals from './Literals'
import * as styles from './styles.module.scss'

/**
 */
export const Submit = () => {
  return (
    <ResetPasswordRequestForm.Submit className={styles.FormButton}>
      <Literals.Submit />
    </ResetPasswordRequestForm.Submit>
  )
}

/**
 */
export const Cancel = () => {
  return (
    <Links.ToLogin className={styles.Cancel}>
      <Literals.Cancel />
    </Links.ToLogin>
  )
}

/**
 */
export const Errors = () => {
  const { formatFormError } = Literals.useLiterals()

  return <ResetPasswordRequestForm.Errors formatError={formatFormError} />
}
