import React from 'react'

import {
  translated,
  useTranslation,
} from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Password = translated('module.auth')('Field.Labels.Password')

/**
 */
export const PasswordConfirm = translated('module.auth')(
  'Field.Labels.PasswordConfirm',
)

/**
 */
export const Submit = translated('pages')(
  'ResetPasswordConfirm.Flow.Steps.InputStep.Form.Submit',
)

/**
 */
export const useLiterals = () => {
  const { t } = useTranslation('module.auth')

  const formatPlaceholder = (placeholder, ...options) => {
    return t(`Field.Placeholder.${placeholder}`, ...options)
  }

  const formatFormError = (key, ...options) => {
    return t(key, ...options)
  }

  const formatFieldError = (key, ...options) => {
    return t(`Form.Error.${key}`, ...options)
  }

  return { formatPlaceholder, formatFormError, formatFieldError }
}
