import React from 'react'

import * as styles from './styles.module.scss'

/**
 *
 */
export const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 *
 */
export const Title = ({ children }) => (
  <div className={styles.TitleWrapper}>
    <h2 className={styles.Title}>{children}</h2>
  </div>
)

/**
 *
 */
export const Body = ({ children }) => (
  <div className={styles.Body}>{children}</div>
)

/**
 *
 */
export const Links = ({ children }) => (
  <div className={styles.Links}>
    <div>{children}</div>
  </div>
)