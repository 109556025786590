// extracted by mini-css-extract-plugin
export var Item_Container = "styles-module--Item_Container--gS7iP";
export var Items_Container = "styles-module--Items_Container--IHeNv";
export var LinkToRegistration = "styles-module--LinkToRegistration--oBk6g";
export var MenuItem_Icon = "styles-module--MenuItem_Icon--Qm19V";
export var MenuText = "styles-module--MenuText--kKDG5";
export var Navi_Container = "styles-module--Navi_Container--VP2fo";
export var Navi_Icon = "styles-module--Navi_Icon--AvvYN";
export var Navi_Item = "styles-module--Navi_Item---+CXs";
export var Navi_Items = "styles-module--Navi_Items--M3yQM";
export var Navi_Trigger = "styles-module--Navi_Trigger--0kCVg";