/**
 *
 * ```
 * ObjectHelper.filterProperties({ foo: foo, bar: bar}, ['foo'])
 * ```
 */
export const filterProperties = (obj, filterKeys) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => {
      return filterKeys.includes(key)
    }),
  )
}
