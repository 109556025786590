import React from 'react'

import * as Base from '@arch-log/webapp.shared/GlobalPageLayout'

export const Layout = ({
  children,
  enableLogoLink = true,
  enableSearch = false,
  enableSignIn = false,
  enableSignUp = true,
  showFooterLinks = true,
  ...props
}) => (
  <Base.Default
    enableLogoLink={enableLogoLink}
    enableSearch={enableSearch}
    enableSignIn={enableSignIn}
    enableSignUp={enableSignUp}
    showFooterLinks={showFooterLinks}
    {...props}
  >
    {children}
  </Base.Default>
)
