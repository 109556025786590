import { createActions } from 'redux-actions'
import { ACTION_PREFIX } from './consts'

export const {
  init: { request: init, success: initSuccess, failure: initFailure },
  authenticated,
  login: {
    request: login,
    success: loginSuccess,
    failure: loginFailure,
    setPermissions,
  },
  logout: { request: logout, success: logoutSuccess, failure: logoutFailure },
  loginForm: { submit: loginFormSubmit },
  changePasswordForm: { submit: changePasswordFormSubmit },
  changePassword: {
    request: changePassword,
    success: changePasswordSuccess,
    failure: changePasswordFailure,
  },
  resetPassword: {
    request: resetPassword,
    success: resetPasswordSuccess,
    failure: resetPasswordFailure,
  },
  resetPasswordRequestForm: { submit: resetPasswordRequestFormSubmit },
  resetPasswordConfirm: {
    request: resetPasswordConfirm,
    success: resetPasswordConfirmSuccess,
    failure: resetPasswordConfirmFailure,
  },
  resetPasswordConfirmForm: { submit: resetPasswordConfirmFormSubmit },
  clearErrors,
} = createActions(
  {
    INIT: {
      REQUEST: () => ({}),
      SUCCESS: ({ isAuthenticated, uid = null }) => ({ isAuthenticated, uid }),
      FAILURE: (error) => ({ error }),
    },
    AUTHENTICATED: (uid) => ({ uid }),
    LOGIN: {
      REQUEST: ({ username, password, rememberme }) => ({
        username,
        password,
        rememberme,
      }),
      SUCCESS: ({ account, activeProject, projectPermissions }) => ({
        account,
        activeProject,
        projectPermissions,
      }),
      FAILURE: (error) => ({ error }),
    },
    LOGIN_FORM: {
      SUBMIT: (data, dispatch, props) => ({ data, dispatch, props }),
    },
    LOGOUT: {
      REQUEST: ({ onComplete }) => ({ onComplete }),
      SUCCESS: () => ({}),
      FAILURE: (error) => ({ error }),
    },
    CHANGE_PASSWORD_FORM: {
      SUBMIT: (data, dispatch, props) => ({ data, dispatch, props }),
    },
    CHANGE_PASSWORD: {
      REQUEST: (data) => ({ data }),
      SUCCESS: () => ({}),
      FAILURE: (error) => ({ error }),
    },
    RESET_PASSWORD: {
      REQUEST: ({ email }) => ({ email }),
      SUCCESS: () => ({}),
      FAILURE: (error) => ({ error }),
    },
    RESET_PASSWORD_REQUEST_FORM: {
      SUBMIT: (data, dispatch, props) => ({ data, dispatch, props }),
    },
    RESET_PASSWORD_CONFIRM: {
      REQUEST: ({ password, token }) => ({ password, token }),
      SUCCESS: () => ({}),
      FAILURE: (error) => ({ error }),
    },
    RESET_PASSWORD_CONFIRM_FORM: {
      SUBMIT: (data, dispatch, props) => ({ data, dispatch, props }),
    },
    CLEAR_ERRORS: () => ({}),
  },
  {
    prefix: ACTION_PREFIX,
  },
)
