import React from 'react'

import * as Links from '@arch-log/webapp.shared/Links'
import * as Buttons from '@arch-log/webapp.shared/Buttons'
import { useNavigate } from '@arch-log/webapp.lib/react.hook'
import { Routes } from '@arch-log/webapp.shared/AppDefinitions/Registration'
import * as Styled from './Styled'
import * as Literals from './Literals'
import * as styles from './styles.module.scss'

/**
 */
export const ForgotPassowrd = ({
  components: { Wrapper = Styled.LinkContainer } = {},
  isVisible = true,
  className = styles.Link,
}) => {
  if (!isVisible) {
    return null
  }

  return (
    <Wrapper>
      <Links.Auth.ToResetPassword className={className}>
        <Literals.ForgotPassword />
      </Links.Auth.ToResetPassword>
    </Wrapper>
  )
}

/**
 */
export const Registration = ({
  components: { Wrapper = Styled.RegistrationContainer } = {},
  isVisible = true,
  className = styles.RegisterButton,
}) => {
  const { signUpText } = Literals.useLiterals();
  const { redirectTo } = useNavigate();
  if (!isVisible) {
    return null
  }
  const toRegistrationTop = () => {
    redirectTo(Routes.toTop());
  };

  return (
    <Wrapper>
      <Styled.LiteralsRegistration />
      <Buttons.DefaultButton className={className} onClick={(e) => { toRegistrationTop() }}>
        {signUpText()}
      </Buttons.DefaultButton>
    </Wrapper>
  )
}

/**
 * 
 */
export const horizontalLine = ({
  isVisible = true,
  className = styles.HorizontalLine,
  ...props
}) => {
  if (!isVisible) {
    return null
  }
  return (
    <hr className={className} {...props} />
  )
}
