import { all, call, put, race, take, takeLeading } from 'redux-saga/effects'

import { log } from './logger'
import * as actions from './actions'
import * as api from './api'
import { formToModel } from './components/Form/Helper'
import { formToModel as changeEmailFormtoModel } from './components/ChangeEmailForm/Helper'
import { getErrorMessage } from '@arch-log/webapp.modules/utils/components/FormTemplate/utils/FormHelper'
import * as form from 'redux-form'

/**
 * Initialize
 */
function* handleInit(action) {
  try {
    log(action)

    yield put(actions.initSuccess())
  } catch (e) {
    console.error(e)
    yield put(actions.initFailure(e))
  }
}

/**
 *
 */
function* handleUpdateMe(action) {
  try {
    log(action)
    //
    const res = yield call(api.UpdateMe, action.payload.data)

    yield put(actions.updateMeSuccess(res.data))
  } catch (e) {
    console.error(e)
    yield put(actions.updateMeFailure(e))
  }
}

/**
 *
 */
function* handleSubmitUpdateMeForm(action) {
  try {
    log(action)

    yield put(form.startSubmit('account'))

    yield put(actions.updateMe(formToModel(action.payload.data)))

    const [success, failure] = yield race([
      take('MODULE/ACCOUNT/ME/UPDATE/SUCCESS'),
      take('MODULE/ACCOUNT/ME/UPDATE/FAILURE'),
    ])

    if (failure) {
      throw failure
    }

    yield put(form.stopSubmit('account'))
    yield put(form.setSubmitSucceeded('account'))

    if (action.payload.props.onSubmitSuccess) {
      action.payload.props.onSubmitSuccess(
        null,
        action.payload.dispatch,
        action.payload.props,
      )
    }
  } catch (e) {
    console.error(e)

    try {
      yield put(form.stopSubmit('account', { _error: 'SubmitFailed' }))

      yield put(form.setSubmitFailed('account', ...action.payload.props.fields))

      if (action.payload.props.onSubmitFail) {
        action.payload.props.onSubmitFail(
          { _error: 'submit error' },
          action.payload.dispatch,
          e,
          action.payload.props,
        )
      }
    } catch (e) {
      console.error(e)
    }
  }
}

/**
 *
 */
function* handleUpdateEmail(action) {
  try {
    log(action)

    const res = yield call(api.changeEmail, action.payload.data)

    yield put(actions.updateEmailSuccess(res.data))
  } catch (e) {
    console.error(e)
    yield put(actions.updateEmailFailure(e))
  }
}
/**
 */
function* handleSubmitUpdateChangeEmailForm(action) {
  try {
    log(action)

    yield put(form.startSubmit('account_changeEmail'))

    yield put(actions.updateEmail(changeEmailFormtoModel(action.payload.data)))

    const [success, failure] = yield race([
      take('MODULE/ACCOUNT/ME/UPDATE_EMAIL/SUCCESS'),
      take('MODULE/ACCOUNT/ME/UPDATE_EMAIL/FAILURE'),
    ])

    if (failure) {
      throw failure
    }

    yield put(form.stopSubmit('account_changeEmail'))
    yield put(form.setSubmitSucceeded('account_changeEmail'))

    if (action.payload.props.onSubmitSuccess) {
      action.payload.props.onSubmitSuccess(
        null,
        action.payload.dispatch,
        action.payload.props,
      )
    }
  } catch (e) {
    console.error(e)

    try {
      yield put(
        form.stopSubmit('account_changeEmail', {
          _error: getErrorMessage(e.payload.error),
        }),
      )

      yield put(
        form.setSubmitFailed('account_changeEmail', [
          action.payload.props.values,
        ]),
      )

      if (action.payload.props.onSubmitFail) {
        action.payload.props.onSubmitFail(
          { _error: 'submit error' },
          action.payload.dispatch,
          e,
          action.payload.props,
        )
      }
    } catch (e) {
      console.error(e)
    }
  }
}

/**
 *
 */
function* handleLoadMe(action) {
  try {
    log(action)
    //
    const res = yield call(api.loadMe)

    yield put(actions.loadMeSuccess(res))
  } catch (e) {
    console.error(e)
    yield put(actions.loadMeFailure(e))
  }
}

/**
 *
 */
export function* sagas() {
  yield all([
    takeLeading('MODULE/ACCOUNT/INIT/REQUEST', handleInit),
    takeLeading('MODULE/ACCOUNT/ME/LOAD/REQUEST', handleLoadMe),
    takeLeading('MODULE/ACCOUNT/ME/UPDATE/REQUEST', handleUpdateMe),
    takeLeading('MODULE/ACCOUNT/ME/UPDATE_EMAIL/REQUEST', handleUpdateEmail),
    takeLeading(
      'MODULE/ACCOUNT/ME/UPDATE_FORM/SUBMIT',
      handleSubmitUpdateMeForm,
    ),
    takeLeading(
      'MODULE/ACCOUNT/ME/UPDATE_CHANGE_EMAIL_FORM/SUBMIT',
      handleSubmitUpdateChangeEmailForm,
    ),
  ])
}
