import React from 'react'

// Import de
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../actions'
import * as Log from '@arch-log/webapp.modules/log/components'

/**
 */
class BaseForm extends React.Component {
  componentDidMount = () => {
    this.props.initForm()
  }

  render = () => {
    const { form, handleSubmit, children } = this.props

    return (
      <form id={form} onSubmit={handleSubmit}>
        {children}
      </form>
    )
  }
}

/**
 */
export const LoginForm = connect(
  //mapStateToProps
  null,
  //mapDispatchToProps
  (dispatch) =>
    bindActionCreators(
      {
        initForm: actions.clearErrors,
      },
      dispatch,
    ),
)(
  reduxForm({
    form: 'auth_login',
    submitAsSideEffect: true,
    onSubmit: actions.loginFormSubmit,
    onSubmitSuccess: (result) => {
      const { setCookiesLoginInfo, pushLoginEvent } = Log.gtmEventDispatcher()
      pushLoginEvent(result.account)
      setCookiesLoginInfo(result.account)
    },
  })(BaseForm),
)
